import {Component, Input, OnInit} from '@angular/core';
import {CustomerOrderQuantity} from "../../../shared/model/CustomerOrderQuantity";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-inactive-customers',
    templateUrl: './inactive-customers.component.html',
    styleUrls: ['./inactive-customers.component.css']
})
export class InactiveCustomersComponent implements OnInit {

    @Input() inactiveCustomers: CustomerOrderQuantity[] = [];

    constructor(private dialogRef: MatDialogRef<InactiveCustomersComponent>) {
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }
}
