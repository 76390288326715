import {Component, OnInit} from '@angular/core';
import {Globals} from "../../shared/globals";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {KeycloakMonitorService} from "../../shared/services/keycloak-monitor.service";

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.css']
})
export abstract class BaseComponent implements OnInit {

    constructor(protected globals: Globals,
                protected dialog: MatDialog,
                protected keycloakMonitorService: KeycloakMonitorService) {
    }

    ngOnInit(): void {
        //console.log("ngOnInit");
        this.keycloakMonitorService.loggedIn$.subscribe((keycloak) => {
            if (keycloak) {
                this.initComp();
            }
        });
    }

    abstract initComp(): void;

}
