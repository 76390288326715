import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CustomerGroupType, CustomerGroupTypeKey, is_customer_group_type_key} from "../../../shared/model/CustomerGroupType";

@Component({
    selector: 'app-customer-group-select',
    templateUrl: './customer-group-select.component.html',
    styleUrls: ['./customer-group-select.component.css']
})
export class CustomerGroupSelectComponent implements OnInit {

    @Output() change = new EventEmitter<CustomerGroupTypeKey>();
    _CustomerGroupType = CustomerGroupType;
    _Object = Object;
    constructor() {}

    ngOnInit(): void {}

    selectGroup(type: string) {
        if( is_customer_group_type_key(type)){
            this.change.emit(type);
        }
    }

    public get_group_type_description(type: string): CustomerGroupType | undefined {
        if ( is_customer_group_type_key(type)){
            return CustomerGroupType[type];
        }
        return undefined;
    }
}
