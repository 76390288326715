import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Distribution} from "../model/Distribution";
import {RestService} from "./rest.service";
import {Observable} from "rxjs";
import {Truck} from "../model/Truck";
import {TruckConfirmation} from "../model/TruckConfirmation";
import {Globals} from "../globals";

@Injectable({
  providedIn: 'root'
})
export class TruckService {

  constructor(protected globals: Globals,
              protected http: HttpClient,
              private restService: RestService) { }

    getTruck(): Observable<Truck> {
        let getPath = this.globals.getRestBase() + "truck/order";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<Truck>(getPath, options);
    }

    getTruckCount(): Observable<any> {
        let getPath = this.globals.getRestBase() + "truck/count";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<any>(getPath, options);
    }


    completeOrder(): Observable<TruckConfirmation> {
        let getPath = this.globals.getRestBase() + "truck/complete";
        //console.log("TruckService truck Complete getPath", getPath);
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<TruckConfirmation>(getPath, options);
    }
}
