import {Component, OnInit} from '@angular/core';
import {AdminService} from "../../../../shared/services/admin.service";
import {ApplicationSystemParameter} from "../../../../shared/model/ApplicationSystemParameter";

@Component({
    selector: 'app-parameters',
    templateUrl: './parameters.component.html',
    styleUrls: ['./parameters.component.css']
})
export class ParametersComponent implements OnInit {

    parameters: ApplicationSystemParameter[] = [];

    constructor(private service: AdminService) {
        //console.log("ParametersComponent.constructor() called");
        service.getParameters().subscribe( data => {
            this.parameters = data;
        });
    }

    ngOnInit(): void {
    }

}
