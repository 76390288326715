import {Component, OnInit} from '@angular/core';
import {TradeshowGrouping} from "../../../../shared/model/TradeshowGrouping";
import {AdminService} from "../../../../shared/services/admin.service";
import {take} from "rxjs/operators";

@Component({
    selector: 'app-tradeshow-groupings',
    templateUrl: './tradeshow-groupings.component.html',
    styleUrls: ['./tradeshow-groupings.component.css']
})
export class TradeshowGroupingsComponent implements OnInit {

    tradeshowGroupings: TradeshowGrouping[] = [];

    constructor(private service: AdminService) {
        service.getTradeshowGroupingList().pipe(take(1)).subscribe( data => {
            this.tradeshowGroupings = data;
        });
    }

    ngOnInit(): void {
    }

}
