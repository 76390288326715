import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-distributions-by-tradeshow',
  templateUrl: './distributions-by-tradeshow.component.html',
  styleUrls: ['./distributions-by-tradeshow.component.css']
})
export class DistributionsByTradeshowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
