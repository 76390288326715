import {Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {Subject} from "rxjs";

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styles: [`
        .help-content {
            float: right;
        }

        .help-button {
            border: none;
        }

        .help-controls {
            display: inline-block;
        }

        .help-action {
            display: inline-block;
            background-image: url("/img/help.png");
            background-position-x: 0px;
            background-position-y: 100%;
            height: 22px;
            width: 23px;
        }
    `]
})
export class HelpComponent implements OnInit {

    private openSubject = new Subject<number>();
    public open$ = this.openSubject.asObservable();
    private nextSubject = new Subject<void>();
    public next$ = this.nextSubject.asObservable();
    private previousSubject = new Subject<void>();
    public previous$ = this.previousSubject.asObservable();
    private closeSubject = new Subject<void>();
    public close$ = this.closeSubject.asObservable();

    helpHidden: boolean = true;

    constructor() {}

    ngOnInit(): void {}

    toggleHelp(): void {
        if( this.helpHidden ) {
            this.openHelp();
        } else {
            this.closeHelp();
        }
    }

    openHelp() {
        this.helpHidden = false;
        this.openSubject.next(0); // open first tooltip
    }

    previousHelp() {
        this.previousSubject.next();
    }

    nextHelp() {
        this.nextSubject.next();
    }

    closeHelp() {
        this.helpHidden = true;
        this.closeSubject.next();
    }
}
