import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            cancelText: string,
            confirmText: string,
            message: string,
            title: string
        }, private mdDialogRef: MatDialogRef<AlertDialogComponent>) {
    }

    ngOnInit(): void {
    }

    public cancel() {
        this.close(false);
    }

    public close(value: any) {
        this.mdDialogRef.close(value);
    }

    public confirm() {
        this.close(true);
    }

    @HostListener("keydown.esc")
    public onEsc() {
        this.close(false);
    }

}
