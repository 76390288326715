import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter, Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {BehaviorSubject, EMPTY, Observable, Observer, Subject} from "rxjs";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {Distribution} from "../../../shared/model/Distribution";
import {FormControl} from "@angular/forms";
import {map, startWith, take} from "rxjs/operators";
import {AllocateService} from "../../../shared/services/allocate.service";
import {CustomerFilter} from "../../../shared/model/CustomerFilter";
import {LoginType} from "../../../shared/model/LoginType";

type CustomerFilterChange = { previousValue?: CustomerFilter, firstChange: boolean, currentValue?: CustomerFilter };

@Component({
    selector: 'app-ship-date',
    templateUrl: './ship-date.component.html',
    styleUrls: ['./ship-date.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipDateComponent implements OnInit, OnChanges {

    loading$: Observable<boolean> = EMPTY;

    @ViewChild('tt9', {static: false}) tt9: NgbTooltip | undefined;

    private customerFilterSubject: BehaviorSubject<CustomerFilter> = new BehaviorSubject<CustomerFilter>({});
    public customerFilter$: Observable<CustomerFilter> = this.customerFilterSubject.asObservable();

    @Input() distribution?: Distribution;
    @Input() public customerFilter?: CustomerFilter;

    @Output() shipDateChanged = new EventEmitter<string>();
    @Output() customerFilterChanged = new EventEmitter<CustomerFilter>();

    shipDatesText: string = "";

    private shipDateSelectVisibleSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
    public shipDateSelectVisible$: Observable<boolean> = this.shipDateSelectVisibleSubject.asObservable();
    // @ts-ignore
    shipDate = new FormControl(new Date());

    minShipDate = "";
    maxShipDate = "";

    events: string[] = [];

    constructor(private allocateService: AllocateService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.distribution) {
            //console.log("ShipDateComponent distribution changed: ", this.distribution);
            if (this.distribution) {
                this.shipDate = new FormControl(this.getNextDate("" + this.distribution?.firstShipDate));
                this.minShipDate = this.getDateISO("" + this.distribution?.firstShipDate);
                //console.log("minShipDate:", this.minShipDate);
                this.maxShipDate = this.getNextDateISO("" + this.distribution?.lastShipDate);
                //console.log("maxShipDate:", this.maxShipDate);
                this.shipDateChanged.emit(this.distribution?.firstShipDate);
                //console.log("ShipDateComponent distribution changed this.customerFilter: ", this.customerFilter);
                if (this.customerFilter) {
                    //console.log("ShipDateComponent distribution changed this.customerFilter: ", this.customerFilter);
                    this.checkForOtherDates();
                }
            }
        }
        if (changes.customerFilter) {
            const {previousValue, firstChange, currentValue}: CustomerFilterChange = changes.customerFilter;
            if (this.customerFilter && currentValue) {
                //console.log("ShipDateComponent customerFilter changed: ", this.customerFilter);
                if (this.customerFilter.shipDate) {
                    this.shipDate = new FormControl(this.getNextDate(this.customerFilter.shipDate));
                    //console.log("setting form to shipDate:", this.customerFilter.shipDate);
                }
                this.checkForOtherDates();
                this.customerFilterSubject.next(this.customerFilter);
            }
        }
    }

    private getDate(date: string): Date {
        let nextDate = new Date(date);
        nextDate.setDate(nextDate.getDate());
        return nextDate;
    }

    private getDateISO(date: string): string {
        let nextDate = new Date(date);
        nextDate.setDate(nextDate.getDate());
        return nextDate.toISOString().substring(0, 10);
    }

    private getNextDate(date: string): Date {
        let nextDate = new Date(date);
        nextDate.setDate(nextDate.getDate() + 1);
        return nextDate;
    }

    private getNextDateISO(date: string): string {
        let nextDate = new Date(date);
        nextDate.setDate(nextDate.getDate() + 1);
        return nextDate.toISOString().substring(0, 10);
    }

    private checkForOtherDates() {
        //console.log("ShipDateComponent checkForOtherDates customerFilter: ", this.customerFilter);
        if (this.customerFilter &&
            this.customerFilter.customerGroupType &&
            this.customerFilter.customerGroupId &&
            this.customerFilter.distributionId &&
            this.customerFilter.shipDate
        ) {
            //console.log("ShipDateComponent checkForOtherDates customerFilter shipDate: ", this.customerFilter.shipDate);
            const observer: Observer<string[]> = {
                next: data => {
                    //console.log("checkForOtherDates data:", data);
                    if (data.length > 0) {
                        this.shipDatesText = `${data.length} ship date${data.length > 1 ? 's':''} allocated for this item...`;
                    }
                },
                error: _ => {
                },
                complete: () => {
                }
            };
            const serviceCall = this.allocateService.getShipDatesForDistribution(this.customerFilter!)
                .pipe(
                    take(1),
                    map( (x: string[] | string) : string[] => x instanceof Array ? [...x] : x ? [x] : [] )
                    );
            this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
            serviceCall.subscribe(observer);
        }
    }

    showShipDateSelect() {
        this.shipDateSelectVisibleSubject.next(true);
        let custFilter = this.customerFilterSubject.getValue();
        let tempFilter: CustomerFilter = {
            loginType: custFilter.loginType,
            customerGroupType: custFilter.customerGroupType,
            customerGroupId: custFilter.customerGroupId,
            divPart: custFilter.divPart,
            mcLaneDiv: custFilter.mcLaneDiv,
            distributionId: custFilter.distributionId,
            itemNumber: custFilter.itemNumber,
            shipDate: this.minShipDate
        };
        //console.log("ShipDateComponent showShipDateSelect tempFilter=", tempFilter);
        this.customerFilterSubject.next(tempFilter);
    }

    onShipDateSelect($event: any) {
        //console.log("onShipDateSelect event={}", $event);
        this.shipDateSelectVisibleSubject.next(false);
        this.shipDateChanged.emit($event);
        if (this.distribution) {
            let custFilter = this.customerFilterSubject.getValue();
            let tempFilter: CustomerFilter = {
                loginType: custFilter.loginType,
                customerGroupType: custFilter.customerGroupType,
                customerGroupId: custFilter.customerGroupId,
                divPart: custFilter.divPart,
                mcLaneDiv: custFilter.mcLaneDiv,
                distributionId: custFilter.distributionId,
                itemNumber: custFilter.itemNumber,
                shipDate: $event
            };
            //console.log("ShipDateComponent onShipDateSelect tempFilter=", tempFilter);
            this.customerFilterSubject.next(tempFilter);
        }
    }

    addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
        //console.log(`${type}: ${event.value}`);
        this.events.push(`${type}: ${event.value}`);
        let newDate: Date = new Date(event.value+"");
        this.shipDateChanged.emit(newDate.toISOString().substring(0, 10));
    }
}
