import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AlertDialogComponent} from "../../pages/components/alert-dialog/alert-dialog.component";
import {ConfirmDialogComponent} from "../../pages/components/confirm-dialog/confirm-dialog.component";
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    private alertDialogRef?: MatDialogRef<AlertDialogComponent>;
    private confirmDialogRef?: MatDialogRef<ConfirmDialogComponent>;

    constructor(private dialog: MatDialog) { }

    public alert(options: {
        title?: string,
        message?: string,
        confirmText?: string
    }): Observable<any> {
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            data: {
                title: options.title,
                message: options.message,
                confirmText: options.confirmText
            }
        });
        return this.alertDialogRef.afterClosed().pipe(take(1), map(result => result));
    }

    public confirm(options: {
            title?: string,
            message?: string,
            cancelText?: string,
            confirmText?: string
        }): Observable<any> {
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: options.title,
                message: options.message,
                cancelText: options.cancelText,
                confirmText: options.confirmText
            }
        });
        return this.confirmDialogRef.afterClosed().pipe(take(1), map(result => result));
    }
}
