import { Injectable } from '@angular/core';

@Injectable()
export class UtilService {
	constructor() {}

	getHost() {
		const url = window.location.href;
		const arr = url.split('/');
		return arr[0] + '//' + arr[2] + '/';
	}
}
