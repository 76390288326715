import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
    public rest_url: string | undefined;
    private ui_base: string = "/mclane-edge-ui/";
    private rest_base: string = "/mclane-edge-ws/rest/v1/";

    private getBase(): string  {
        let base = "";
        if (window.location.hostname.includes('localhost')) {
            base = "http://localhost:9080";
        } else if (window.location.hostname.includes('appstest') || window.location.hostname.includes('mclaneedgedev.wpengine.com') || window.location.hostname.includes('mclaneedgestag.wpengine.com')) {
            base = "https://appstest.mclaneco.com";
        } else {
            base = "https://apps.mclaneco.com";
        }
        return base;
    }

    public getUiBase(): string {
        let base = this.getBase() + this.ui_base;
        if (window.location.hostname.includes('mclaneedge.com')) {
            base = "https://www.mclaneedge.com" + this.ui_base;
        } else if (window.location.hostname.includes('mclaneedgedev')) {
            base = "https://mclaneedgedev.wpengine.com" + this.ui_base;
        }
        //console.log('getUIBase(): ', base);
        return base;
    }

    public getRestBase(): string {
        let base = this.getBase() + this.rest_base;
        //console.log('>>>> getRestBase(): ', base);
        return base;
    }

    isEdgeHost(): boolean {
        if (window.location.hostname.includes('localhost')) {
            return false;
        }
        return true;
    }
}
