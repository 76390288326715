import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable} from "rxjs";
import {Category} from "../../shared/model/Category";
import {take, tap} from "rxjs/operators";
import {DistributionService} from "../../shared/services/distribution.service";
import {Distribution} from "../../shared/model/Distribution";
import {ItemUtil} from "../../shared/util/ItemUtil";
import {Globals} from "../../shared/globals";
import {BaseComponent} from "../base/base.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../shared/services/dialog.service";
import {KeycloakMonitorService} from "../../shared/services/keycloak-monitor.service";

export interface KeyValue<T>{
    [key:string]:T;
}

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styles: [`
        .category {
            padding: 20px;
        }

        span.smsstyle_Detail_Link {
            font-size: 21px;
            display: block;
            padding: 15px 20px;
            width: 100%;
            height: auto;
            margin: 20px;
        }

        .smsstyle_Detail_Link a {
            text-decoration: none;
        }

        .smsstyle_img_blacklink {
            margin-bottom: 10px;
        }

        #categorySupplierList .smsstyle_Header td {
            font-size: 33px;
            font-weight: 600;
            letter-spacing: -0.04em;
            padding-bottom: 6px;
            border-bottom: 5px solid #0056b8;
            line-height: 1;
            width: 100%;
        }

        #categorySupplierList .smsstyle_Header {
            padding-bottom: 10px;
            width: 100%;
        }

        #categorySupplierList .smsstyle_Detail td {
            display: block;
            padding: 20px 20px 40px 20px;
            margin: 0;
            font-size: 21px;
        }

        #categorySupplierList .smsstyle_Detail td .smsstyle_Detail_Link a {
            color: #0056b8;
            text-decoration: none;
            font-size: 33px;
            margin: 0;
        }
    `]
})
export class CategoryComponent extends BaseComponent implements OnInit {

    // Busy
    loading$: Observable<boolean> = EMPTY;

    // Page filter
    @Input() filter: string = "special";

    // Page Data
    categoryDistributions: Map<Category, Array<Distribution>> = new Map();
    sortedCategories: Category[] = [];
    categoryLookup: KeyValue<Category> = {};
    distributionLookup: KeyValue<Distribution[]> = {};
    sortedCategoryDistributions: Map<string, Array<Distribution>> = new Map();
    public keys: (o:any)=>string[] = Object.keys;
    showNoOffersMessage = true;

    constructor(protected globals: Globals,
                public dialog: MatDialog,
                private dialogService: DialogService,
                protected service: DistributionService,
                protected keycloakMonitorService: KeycloakMonitorService) {
        super(globals, dialog, keycloakMonitorService);
    }

    ngOnInit(): void {
        //console.log("ngOnInit");
        super.ngOnInit();
    }

    initComp() {
        //console.log("Initializing Component");
        if (this.filter) {
            let subject = new BehaviorSubject(true);
            this.loading$ = subject.asObservable();
            let serviceCall = this.service.getCategoryDistributions(this.filter).pipe(take(1),
                tap(_ => subject.next(false)));
            serviceCall.subscribe(({categoryLookup, distributionLookup}) => {
                //console.log("Got data", categoryLookup, distributionLookup);
                this.categoryLookup = categoryLookup as any;
                Object.keys(this.categoryLookup).forEach(key => { this.sortedCategories.push(this.categoryLookup[key])});
                this.sortedCategories = this.sortedCategories.sort((c1, c2) => (c1.name > c2.name) ? 1 : -1);
                //console.log("this.sortedCategories: ", this.sortedCategories);

                this.distributionLookup = distributionLookup as any;
                Object.keys(this.distributionLookup).forEach(key => {
                    let sortedDistArray = this.distributionLookup[key]
                        .sort((d1, d2) => (d1.itemSet![0].supplier.name > d2.itemSet![0].supplier.name) ? 1: -1);
                    this.sortedCategoryDistributions.set(key, sortedDistArray);
                });
                //console.log("this.sortedCategoryDistributions: ", this.sortedCategoryDistributions);
                if (Object.keys(distributionLookup).length) {
                    this.showNoOffersMessage = false;
                }
            });
        }
    }

    getItemLink(distribution: Distribution, category: string) {
        let target = this.globals.getUiBase() + "distribution";
        //console.log("1) getItemLink: ", target);
        let catItems = distribution.itemSet?.filter(item => item.category.id === category);
        if (catItems && this.globals.isEdgeHost()) {
            target = catItems[0].cmsUrl;
        }
        target = target + "?key=" + distribution.key?.split('_')[0]
        //console.log("2) getItemLink: ", target);
        return target;
    }

    getItemImageUrl(distribution: Distribution, category: string) {
        let catItems = distribution.itemSet?.filter(item => item.category.id === category);
        if (catItems) {
            return catItems[0].imageUrl;
        }
        return '';
    }

    getItemImageAlt(distribution: Distribution, category: string) {
        let catItems = distribution.itemSet?.filter(item => item.category.id === category);
        if (catItems) {
            return catItems[0].altDescription;
        }
        return distribution.itemSet![0]!.altDescription;
    }

    getItemLabel(distribution: Distribution, category: string) {
        let catItems = distribution.itemSet?.filter(item => item.category.id === category);
        if (catItems) {
            return catItems[0].displayDescription;
        }
        if (this.filter == 'special') {
            return distribution.itemSet![0].supplier.name;
        }
        return distribution.itemSet![0].displayDescription;
    }

    calcDeal(distribution: Distribution): string {
        if (distribution.allowance && distribution.allowance >= 1 && distribution.billCost && distribution.billCost >= 1) {
            let calc = ((distribution.allowance / distribution.billCost) * 100).toFixed(0);
            return `${calc}%\nOFF`;
        }
        return "";
    }

    imageUrlExists(distribution: Distribution, category: string) {
        let catItems = distribution.itemSet?.filter(item => item.category.id === category);
        if (catItems) {
            return catItems[0].imageUrl !== undefined && catItems[0].imageUrl.length > 0;
        }
        return false;
    }
}
