<div class="items-in-truck-select">
    <div class="top-row">
        <span class="close-text" (click)="close()">Close</span>
    </div>
    <div class="distribution-row">
        <table cellspacing="0" cellpadding="0">
            <thead>
                <tr>
                    <th>Supplier</th>
                    <th>Item</th>
                    <th>Bill Cost</th>
                    <th>Allow.</th>
                    <th>Ext.</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of distribution?.itemSet">
                    <td>{{item.supplier.name}}</td>
                    <td>
                        <div style="display:block;padding:0;border:0">{{item.description}}</div>
                        <div style="display:block;padding:0;border:0">{{item.uin}}</div>
                        <div style="display:block;padding:0;border:0">{{item.upc}}</div>
                    </td>
                    <td>{{item.billCost.toLocaleString()}}</td>
                    <td>{{item.allowance.toLocaleString()}}</td>
                    <td>{{item.ext.toLocaleString()}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
