<div class="url-maint" xmlns="http://www.w3.org/1999/html">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <div class="url-maint-content">
            <p>Instructions: Click an Offer to show/hide the Offer's detail and URL edit feature.
                Offers missing Urls are highlighted in <span class="lightyellow">light yellow</span>.</p>
            <mat-tab-group>
                <mat-tab label="Current Special Offers ({{(currentSpecialOffers$|async)?.length || 0}})">
                    <ng-template matTabContent>
                        <ol *ngIf="(currentSpecialOffers$|async)">
                            <ng-container *ngFor="let specialOffer of (currentSpecialOffers$|async)">
                                <app-url-maint-edit urlFor="special" [categoryOffer]="specialOffer" (click)="showDetail(specialOffer, 'CUR_SPECIAL')" [previousCategoryOffer]="previousCategoryOffer" (offerChanged)="onOfferChange($event)"></app-url-maint-edit>
                            </ng-container>
                        </ol>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Current Featured Items ({{(currentFeaturedItems$|async)?.length || 0}})">
                    <ng-template matTabContent>
                        <ol *ngIf="(currentFeaturedItems$|async)">
                            <ng-container *ngFor="let featuredItem of (currentFeaturedItems$|async)">
                                <app-url-maint-edit urlFor="featured" [categoryOffer]="featuredItem" (click)="showDetail(featuredItem, 'CUR_FEATURED')" [previousCategoryOffer]="previousCategoryOffer" (offerChanged)="onOfferChange($event)"></app-url-maint-edit>
                            </ng-container>
                        </ol>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Current New Items ({{(currentNewItems$|async)?.length || 0}})">
                    <ng-template matTabContent>
                        <ol *ngIf="(currentNewItems$|async)">
                            <ng-container *ngFor="let newItem of (currentNewItems$|async)">
                                <app-url-maint-edit urlFor="new" [categoryOffer]="newItem" (click)="showDetail(newItem, 'CUR_NEW')" [previousCategoryOffer]="previousCategoryOffer" (offerChanged)="onOfferChange($event)"></app-url-maint-edit>
                            </ng-container>
                        </ol>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Current Advertised Items ({{(currentAdvItems$|async)?.length || 0}})">
                    <ng-template matTabContent>
                        <ol *ngIf="(currentAdvItems$|async)">
                            <ng-container *ngFor="let newItem of (currentAdvItems$|async)">
                                <app-url-maint-edit urlFor="new" [categoryOffer]="newItem" (click)="showDetail(newItem, 'CUR_ADVERT')" [previousCategoryOffer]="previousCategoryOffer" (offerChanged)="onOfferChange($event)"></app-url-maint-edit>
                            </ng-container>
                        </ol>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Future Special Offers ({{(futureSpecialOffers$|async)?.length || 0}})">
                    <ng-template matTabContent>
                        <ol *ngIf="(futureSpecialOffers$|async)">
                            <ng-container *ngFor="let specialOffer of (futureSpecialOffers$|async)">
                                <app-url-maint-edit urlFor="special" [categoryOffer]="specialOffer" (click)="showDetail(specialOffer, 'FTR_SPECIAL')" [previousCategoryOffer]="previousCategoryOffer" (offerChanged)="onOfferChange($event)"></app-url-maint-edit>
                            </ng-container>
                        </ol>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Future Featured Items ({{(futureFeaturedItems$|async)?.length || 0}})">
                    <ng-template matTabContent>
                        <ol *ngIf="(futureFeaturedItems$|async)">
                            <ng-container *ngFor="let featuredItem of (futureFeaturedItems$|async)">
                                <app-url-maint-edit urlFor="featured" [categoryOffer]="featuredItem" (click)="showDetail(featuredItem, 'FTR_FEATURED')" [previousCategoryOffer]="previousCategoryOffer" (offerChanged)="onOfferChange($event)"></app-url-maint-edit>
                            </ng-container>
                        </ol>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Future New Items ({{(futureNewItems$|async)?.length || 0}})">
                    <ng-template matTabContent>
                        <ol *ngIf="(futureNewItems$|async)">
                            <ng-container *ngFor="let newItem of (futureNewItems$|async)">
                                <app-url-maint-edit urlFor="new" [categoryOffer]="newItem" (click)="showDetail(newItem, 'FTR_NEW')" [previousCategoryOffer]="previousCategoryOffer" (offerChanged)="onOfferChange($event)"></app-url-maint-edit>
                            </ng-container>
                        </ol>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Future Advertised Items ({{(futureAdvItems$|async)?.length || 0}})">
                    <ng-template matTabContent>
                        <ol *ngIf="(futureAdvItems$|async)">
                            <ng-container *ngFor="let newItem of (futureAdvItems$|async)">
                                <app-url-maint-edit urlFor="new" [categoryOffer]="newItem" (click)="showDetail(newItem, 'FTR_ADVERT')" [previousCategoryOffer]="previousCategoryOffer" (offerChanged)="onOfferChange($event)"></app-url-maint-edit>
                            </ng-container>
                        </ol>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
