import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, Observer} from "rxjs";
import {map, startWith, take} from "rxjs/operators";
import {AllocateService} from "../../../shared/services/allocate.service";
import {CustomerFilter} from "../../../shared/model/CustomerFilter";
import {FormControl} from "@angular/forms";
import {LoginType} from "../../../shared/model/LoginType";

type CustomerFilterChange = { previousValue?: CustomerFilter, firstChange: boolean, currentValue?: CustomerFilter };

@Component({
    selector: 'app-ship-date-select',
    templateUrl: './ship-date-select.component.html',
    styleUrls: ['./ship-date-select.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipDateSelectComponent implements OnInit, OnChanges {

    loading$: Observable<boolean> = EMPTY;

    @Output() shipDateChanged = new EventEmitter<string>();

    shipDates: string[] = [];
    @Input() public customerFilter?: CustomerFilter;

    private customerFilterSubject: BehaviorSubject<CustomerFilter> = new BehaviorSubject<CustomerFilter>({});
    public customerFilter$: Observable<CustomerFilter> = this.customerFilterSubject.asObservable();

    constructor(private allocateService: AllocateService) {}

    ngOnInit(): void {
        this.checkForOtherDates();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customerFilter) {
            const {previousValue, firstChange, currentValue}: CustomerFilterChange = changes.customerFilter;
            //console.log("ShipDateSelectComponent customerFilter currentValue: ", currentValue);
            if (this.customerFilter && currentValue) {
                //console.log("ShipDateSelectComponent customerFilter changed: ", this.customerFilter);
                this.checkForOtherDates();
            }
        }
    }

    private checkForOtherDates() {
        //console.log("ShipDateSelectComponent customerFilter: ", this.customerFilter);
        if (this.customerFilter &&
            this.customerFilter.customerGroupType &&
            this.customerFilter.customerGroupId &&
            this.customerFilter.distributionId &&
            this.customerFilter.shipDate
        ) {
            //console.log("ShipDateSelectComponent customerFilter shipDate: ", this.customerFilter.shipDate);
            const observer: Observer<string[]> = {
                next: data => {
                    //console.log("ShipDateSelectComponent checkForOtherDates data:", data);
                    this.shipDates = data;
                },
                error: _ => {
                },
                complete: () => {
                }
            };
            const serviceCall = this.allocateService.getShipDatesForDistribution(this.customerFilter!)
                .pipe(
                    take(1),
                    map( (x: string[] | string) : string[] => x instanceof Array ? [...x] : x ? [x] : [] )
                );
            this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
            serviceCall.subscribe(observer);
        }
    }

    selectShipDate(date: string) {
        this.shipDateChanged.emit(date);
    }
}
