<div class="items-in-truck-select">
    <div class="top-row">
        <span class="title-text">Select an Item/Bundle...</span>
        <span class="close-text" (click)="close()">Close</span>
    </div>
    <div class="distribution-row" *ngFor="let distribution of distributionsInTruck" (click)="selectDistribution(distribution)">
        <img class="distribution-image" src="{{getDistributionImageUrl(distribution)}}"/>
        <div class="distribution-detail">
            <div class="distribution-item-desc">{{getDistributionItemAttr(distribution, 'description')}}</div>
            <div class="distribution-item-desc-sub">Item #{{getDistributionItemAttr(distribution, 'id')}}<span style="color: #a42;"> Dist: {{distribution?.description}}</span></div>
        </div>
    </div>
</div>
