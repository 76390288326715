<ng-template #tt10Text>
    Use this to apply the same quantity to all selected stores.  Hint: the 'Sel All' check box will select or deselect all stores in the list.
</ng-template>
<ng-template #tt11Text>
    Select this to apply the selected ship date and store quantities to all Items / bundles in the current order.  If the ship date does not fall within the allowed ship dates for an Item, the ship date will be changed to the first available ship date for the item.
</ng-template>
<ng-template #tt12Text>
    Use this to apply quantities to the stores for the selected Item / Ship Date.
</ng-template>
<div class="store-allocate" [ngClass]="{'disabled':activeCustomerCount < 1}">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <div class="header-section">
            <div class="mass-quantity">
                <input id="apply-qty-to-all-entry"
                    class="apply-to-all-entry" type="number"
                       min="0"
                       [ngbTooltip]="tt10Text"
                       tooltipClass="t10-tooltip"
                       placement="top"
                       triggers="manual"
                       #tt10="ngbTooltip"
                       appTooltip="helpTooltipController"
                       [tooltip]="tt10">
                <button class="apply-to-all-action" (click)="applyQtyToAllAction()">Apply Qty To Selected</button>
            </div>
            <!-- Not functional, and disabled until requested -->
            <button class="apply-to-other-action" style="visibility: hidden"
                    (click)="applyToOther()"
                    [ngbTooltip]="tt11Text"
                    tooltipClass="t11-tooltip"
                    placement="top"
                    triggers="manual"
                    #tt11="ngbTooltip"
                    appTooltip="helpTooltipController"
                    [tooltip]="tt11">Apply To Other Ordered Items</button>
        </div>
        <div class="body-section">
            <table id="allocate-table" mat-table class="customer-table" [dataSource]="customerOrderQuantities" matSort>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                                      [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(row) : null"
                                      [checked]="selection.isSelected(row)"
                                      [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Qty Column -->
                <ng-container matColumnDef="orderQty">
                    <th mat-header-cell *matHeaderCellDef
                        [ngbTooltip]="tt12Text"
                        tooltipClass="t12-tooltip"
                        placement="top"
                        triggers="manual"
                        #tt12="ngbTooltip"
                        appTooltip="helpTooltipController"
                        [tooltip]="tt12">Qty</th>
                    <td mat-cell *matCellDef="let element" (click)="selection.select(element)">
                        <input class="customer-order-qty-entry" type="number" min="0" value="{{element.orderQuantity}}" (change)="quantityChanged($event, element)">
                    </td>
                </ng-container>

                <!-- Div Column -->
                <ng-container matColumnDef="mcLaneDiv">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Div</th>
                    <td mat-cell *matCellDef="let element">{{element.mcLaneDiv}}</td>
                </ng-container>

                <!-- Cust # Column -->
                <ng-container matColumnDef="customerNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cust #</th>
                    <td mat-cell *matCellDef="let element">{{element.customerNumber}}</td>
                </ng-container>

                <!-- Store # Column -->
                <ng-container matColumnDef="storeNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Store #</th>
                    <td mat-cell *matCellDef="let element">{{element.storeNumber}}</td>
                </ng-container>

                <!-- Store Name Column -->
                <ng-container matColumnDef="storeName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Store Name</th>
                    <td mat-cell *matCellDef="let element">{{element.storeName}}</td>
                </ng-container>

                <!-- Address Column -->
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                    <td mat-cell *matCellDef="let element">{{element.address}}</td>
                </ng-container>

                <!-- City Column -->
                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
                    <td mat-cell *matCellDef="let element">{{element.city}}</td>
                </ng-container>

                <!-- ST Column -->
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ST</th>
                    <td mat-cell *matCellDef="let element">{{element.state}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (click)="selection.toggle(row)">
                </tr>
            </table>
        </div>
        <div class="footer-section">
            <div class="customer-count" *ngIf="activeCustomerCount">{{activeCustomerCount}} {{plural(activeCustomerCount,'Customer' )}} found</div>
            <!-- Not functional, and disabled until requested -->
            <div class="inactive-customer-count" *ngIf="inactiveCustomerCount" (click)="showInactiveCustomers()">{{inactiveCustomerCount}} Inactive {{plural(inactiveCustomerCount,'customer' )}} not shown</div>
        </div>
    </div>
</div>
