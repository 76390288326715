import {Component, OnInit} from '@angular/core';
import {EMPTY, Observable, Observer} from "rxjs";
import {ItemUrl} from "../../../../shared/model/ItemUrl";
import {ItemUrlRecord} from "../../../../shared/model/ItemUrlRecord";
import {map, startWith, take} from "rxjs/operators";
import {
    CachedDistributionEntry,
    CachedDistributionLookup, create_cached_distribution_entries
} from "../../../../shared/model/CachedDistributionLookup";
import {AdminService} from "../../../../shared/services/admin.service";
import {CachedDistribution} from "../../../../shared/model/CachedDistribution";

@Component({
    selector: 'app-distribution-lists',
    templateUrl: './distribution-lists.component.html',
    styleUrls: ['./distribution-lists.component.css']
})
export class DistributionListsComponent implements OnInit {

    loading$: Observable<boolean> = EMPTY;

    distributionLists: CachedDistributionEntry[] = [];

    constructor(private service: AdminService) {
    }

    ngOnInit(): void {
        const callObserver: Observer<CachedDistributionLookup> = {
            next: data => {
                //console.log("data:", data);
                this.distributionLists = create_cached_distribution_entries(data);
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const serviceCall = this.service.getCachedDistributionLists().pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }

    getListKey([key, list]: CachedDistributionEntry) {
        return `${key}`;
    }

    getList([key, list]: CachedDistributionEntry): CachedDistribution[] {
        return list;
    }
}
