<ng-template #tt1Text>Click here to select an item or bundle currently in your order</ng-template>
<ng-template #tt2Text>Clicking here will show the details of the selected item or bundle</ng-template>
<ng-template #tt3Text>This will remove the selected Item/Bundle from the order.  Any allocated customers will also be removed.</ng-template>
<div class="item-detail">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <div class="top-row">
            <button id="distribution-items-action"
                    class="distribution-items-action"
                    [ngbTooltip]="tt1Text"
                    tooltipClass="t1-tooltip"
                    placement="top"
                    triggers="manual"
                    #tt1="ngbTooltip"
                    appTooltip="helpTooltipController"
                    [tooltip]="tt1"
                    (click)="showItemsInTruck()">{{itemsInTruckText}}</button>
            <div class="top-right">
                <div class="items-in-truck-action"
                    (click)="showDistributionItems()"
                    [ngbTooltip]="tt2Text"
                    tooltipClass="t2-tooltip"
                    placement="top"
                    triggers="manual"
                    #tt2="ngbTooltip"
                    appTooltip="helpTooltipController"
                    [tooltip]="tt2"></div>
                <div class="delete-from-truck-action"
                     (click)="deleteFromTruck()"
                     [ngbTooltip]="tt3Text"
                     tooltipClass="t3-tooltip"
                     placement="top"
                     triggers="manual"
                     #tt3="ngbTooltip"
                     appTooltip="helpTooltipController"
                     [tooltip]="tt3"></div>
            </div>
        </div>
        <div class="bottom-row">
            <img class="distribution-image" src="{{distributionImageUrl}}"/>
            <div class="distribution-detail">
                <div class="distribution-item-desc">{{getDescription()}}</div>
                <div *ngIf="distribution?.bundle; else notBundleElse"><span style="color: #a42;">Bundle Distribution</span></div>
                <ng-template #notBundleElse>
                    <div class="distribution-item-desc-sub">Item #{{distributionItem?.id}}<span style="color: #a42;text-overflow: ellipsis;"> Dist: {{distribution?.description}}</span></div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
