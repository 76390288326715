import { Injectable }                                          from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService }                  from 'keycloak-angular';
import { KeycloakProfile }                                     from 'keycloak-js';

@Injectable({providedIn: 'root',})
export class AppAuthGuard extends KeycloakAuthGuard {

	private userDetails: KeycloakProfile | undefined;
	private user_roles: Array<string> = [];

	constructor(protected router: Router, protected keycloakService: KeycloakService) {
		super(router, keycloakService);
	}

	isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (!this.authenticated) {
				this.keycloakService.login();
				return reject(false);
			}

			// this.checkUser();

			const requiredRoles = route.data.roles;
			if (!requiredRoles || requiredRoles.length === 0) {
				return resolve(true);
			}
			else {
				if (!this.roles || this.roles.length === 0) {
					resolve(false);
				}
				let granted = false;
				for (const requiredRole of requiredRoles) {
					if (this.roles.indexOf(requiredRole) > -1) {
						granted = true;
						break;
					}
				}
				resolve(granted);
			}
		});
	}
}
