import {Component, OnInit} from '@angular/core';
import {CachedDistribution} from "../../../../shared/model/CachedDistribution";
import {AdminService} from "../../../../shared/services/admin.service";
import {take} from "rxjs/operators";

@Component({
    selector: 'app-distributions',
    templateUrl: './distributions.component.html',
    styleUrls: ['./distributions.component.css']
})
export class DistributionsComponent implements OnInit {

    distributions: CachedDistribution[] = [];

    constructor(private service: AdminService) {
        service.getDistributionList().pipe(take(1)).subscribe( data => {
            this.distributions = data;
        });
    }

    ngOnInit(): void {
    }

}
