import {APP_INITIALIZER, ApplicationRef, DoBootstrap, Injector, NgModule, Type} from '@angular/core';

import {ActionsComponent} from './pages/admin/actions/actions.component';
import {AdminComponent} from './pages/admin/admin.component';
import {AdminService} from "./shared/services/admin.service";
import {AlertDialogComponent} from './pages/components/alert-dialog/alert-dialog.component';
import {AllocateComponent} from './pages/allocate/allocate.component';
import {AllocateService} from "./shared/services/allocate.service";
import {AppAuthGuard} from "./shared/security/keycloak-auth-guard";
import {ApusUserService} from "./shared/services/apus-user.service";
import {BaseComponent} from './pages/base/base.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CategoriesComponent} from './pages/admin/data/categories/categories.component';
import {CategoryComponent} from './pages/category/category.component';
import {CacheRefreshScheduleComponent} from './pages/admin/data/cache-refresh-schedule/cache-refresh-schedule.component';
import {ClipboardModule} from '@angular/cdk/clipboard'
import {ConfirmDialogComponent} from './pages/components/confirm-dialog/confirm-dialog.component';
import {CustomerGroupSelectComponent} from './pages/components/customer-group-select/customer-group-select.component';
import {CustomerSelectComponent} from './pages/components/customer-select/customer-select.component';
import {CustomerService} from "./shared/services/customer.service";
import {DataComponent} from './pages/admin/data/data.component';
import {DialogService} from "./shared/services/dialog.service";
import {DistributionComponent} from './pages/distribution/distribution.component';
import {DistributionItemsComponent} from './pages/components/distribution-items/distribution-items.component';
import {DistributionListsComponent} from './pages/admin/data/distribution-lists/distribution-lists.component';
import {DistributionsComponent} from './pages/admin/data/distributions/distributions.component';
import {DistributionsByTradeshowComponent} from './pages/admin/data/distributions-by-tradeshow/distributions-by-tradeshow.component';
import {DistributionService} from "./shared/services/distribution.service";
import {Globals} from "./shared/globals";
import {HttpClientModule} from "@angular/common/http";
import {HelpComponent} from './pages/components/help/help.component';
import {InactiveCustomersComponent} from './pages/components/inactive-customers/inactive-customers.component';
import {initializer} from "./shared/security/app-init";
import {ItemDetailComponent} from './pages/components/item-detail/item-detail.component';
import {ItemsInTruckSelectComponent} from './pages/components/items-in-truck-select/items-in-truck-select.component';
import {ItemUrlsComponent} from './pages/admin/data/item-urls/item-urls.component';
import {KeycloakAngularModule, KeycloakOptions, KeycloakService} from "keycloak-angular";
import {KeycloakConfigService} from "./shared/services/keycloak-config.service";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatSortModule} from "@angular/material/sort";
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from "@angular/material/table";
import {MatNativeDateModule} from "@angular/material/core";
import {NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ParametersComponent} from './pages/admin/data/parameters/parameters.component';
import {ReactiveFormsModule} from "@angular/forms";
import {RestService} from "./shared/services/rest.service";
import {ShipDateComponent} from './pages/components/ship-date/ship-date.component';
import {ShipDateSelectComponent} from './pages/components/ship-date-select/ship-date-select.component';
import {StoreAllocateComponent} from './pages/components/store-allocate/store-allocate.component';
import {SuppliersComponent} from './pages/admin/data/suppliers/suppliers.component';
import {SuppliersByTradeshowComponent} from './pages/admin/data/suppliers-by-tradeshow/suppliers-by-tradeshow.component';
import {SupplierComponent} from './pages/supplier/supplier.component';
import {TooltipComponent} from './pages/components/help/tooltip/tooltip.component';
import {TooltipControllerDirective} from './shared/directives/tooltip-controller.directive';
import {TooltipDirective} from './shared/directives/tooltip.directive';
import {TradeshowDivisionsComponent} from './pages/admin/data/tradeshow-divisions/tradeshow-divisions.component';
import {TradeshowGroupingsComponent} from './pages/admin/data/tradeshow-groupings/tradeshow-groupings.component';
import {TruckComponent} from './pages/truck/truck.component';
import {TruckCountComponent} from './pages/truck/truck-count/truck-count.component';
import {TruckService} from "./shared/services/truck.service";
import {UtilService} from "./shared/services/util.service";
import {UserService} from "./shared/services/user.service";
import {UrlMaintComponent} from './pages/admin/url-maint/url-maint.component';
import {UrlMaintEditComponent} from './pages/admin/url-maint/url-maint-edit/url-maint-edit.component';
import {createCustomElement} from "@angular/elements";
import {APP_BASE_HREF, CommonModule} from "@angular/common";
import {mergeMap, take} from "rxjs/operators";
import {from} from "rxjs";
import {KeycloakMonitorService} from './shared/services/keycloak-monitor.service';
import {BadgeModule} from "./directive/badge.module";


@NgModule({
    declarations: [
        ActionsComponent,
        AdminComponent,
        AlertDialogComponent,
        AllocateComponent,
        CacheRefreshScheduleComponent,
        CategoryComponent,
        CategoriesComponent,
        ConfirmDialogComponent,
        CustomerGroupSelectComponent,
        CustomerSelectComponent,
        DataComponent,
        DistributionComponent,
        DistributionItemsComponent,
        DistributionsByTradeshowComponent,
        DistributionsComponent,
        HelpComponent,
        ItemDetailComponent,
        ItemsInTruckSelectComponent,
        ItemUrlsComponent,
        ParametersComponent,
        ShipDateComponent,
        ShipDateSelectComponent,
        StoreAllocateComponent,
        SuppliersByTradeshowComponent,
        SupplierComponent,
        SuppliersComponent,
        TradeshowGroupingsComponent,
        TradeshowDivisionsComponent,
        TooltipComponent,
        TooltipControllerDirective,
        TooltipDirective,
        TruckComponent,
        TruckCountComponent,
        InactiveCustomersComponent,
        AlertDialogComponent,
        ConfirmDialogComponent,
        DistributionListsComponent,
        UrlMaintComponent,
        UrlMaintEditComponent,
    ],
    imports: [
        BadgeModule,
        BrowserAnimationsModule,
        BrowserModule,
        ClipboardModule,
        CommonModule,
        HttpClientModule,
        KeycloakAngularModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTableModule,
        NgbModule,
        NgbTooltipModule,
        ReactiveFormsModule,
    ],
    providers: [
        AdminService,
        AllocateService,
        AppAuthGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService, KeycloakConfigService, KeycloakMonitorService]
        },
        ApusUserService,
        CustomerService,
        DialogService,
        DistributionService,
        Globals,
        KeycloakMonitorService,
        MatDialogModule,
        TruckService,
        RestService,
        UtilService,
        UserService,
        {provide: APP_BASE_HREF, useValue: '/'}
    ],
    exports: [CategoryComponent,DistributionComponent,TruckComponent,TruckCountComponent, AdminComponent],
    entryComponents: [CategoryComponent,DistributionComponent,TruckComponent,TruckCountComponent, AdminComponent]
})
export class EdgeWcModule implements DoBootstrap {

    constructor(private injector: Injector, keycloak: KeycloakService, keycloakConfigService: KeycloakConfigService) {
        this.defineElements();
    }

    defineElements(): boolean {
        //console.log('defining elements');
        this.defineElement(TruckCountComponent, 'edge-truck-count');
        this.defineElement(CategoryComponent, 'edge-category');
        this.defineElement(DistributionComponent, 'edge-offer');
        this.defineElement(TruckComponent, 'edge-truck');
        this.defineElement(AdminComponent, 'edge-admin');
        return true;
    }

    defineElement(component: Type<any>, elementName: string) {
        const element = createCustomElement(component, {
            injector: this.injector,

        });
        customElements.define(elementName, element);
        //console.log('defining element:' + elementName);
    }

    ngDoBootstrap(): void {

    }

}
