import { Injectable }  from '@angular/core';
import { Observable }  from "rxjs";
import { environment } from "../../environments/environment";
import { Globals }     from "../globals";
import { RestService } from "./rest.service";
import { UtilService } from "./util.service";

@Injectable({
  providedIn: 'root'
})
export class KeycloakConfigService {

  constructor(private restService: RestService,
              private globals: Globals,
              private utilService: UtilService) {
		if (environment.use_current_url === true) {
			this.globals.rest_url = this.utilService.getHost() + environment.rest_suffix;
		}
	}

	getAuthConfig(): Observable<any> {
		return this.restService.get('keycloak/config/mclane-edge');
	}
}
