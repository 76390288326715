import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BehaviorSubject, EMPTY, from, Observable, Observer} from "rxjs";
import {AdminService} from "../../../shared/services/admin.service";
import {filter, first, flatMap, groupBy, map, mergeMap, reduce, startWith, take} from "rxjs/operators";
import {CachedDistribution} from "../../../shared/model/CachedDistribution";
import {
    CachedDistributionEntry,
    CachedDistributionLookup,
    create_cached_distribution_entries
} from "../../../shared/model/CachedDistributionLookup";
import {Globals} from "../../../shared/globals";
import {CategoryOffer} from "../../../shared/model/CategoryOffer";
import {CategoryOfferItem} from "../../../shared/model/CategoryOfferItem";
import {handle_null} from "../../../shared/util/ObjectUtil";
import {Entry} from "../../../shared/model/entry";
import {BaseComponent} from "../../base/base.component";
import {MatDialog} from "@angular/material/dialog";
import {KeycloakMonitorService} from "../../../shared/services/keycloak-monitor.service";

@Component({
    selector: 'app-url-maint',
    templateUrl: './url-maint.component.html',
    styleUrls: ['./url-maint.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlMaintComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() selectedParentTabName = '';

    loading$: Observable<boolean> = EMPTY;

    distributionLists: CachedDistributionEntry[] = [];

    // @ts-ignore
    _currentSpecialOffers: BehaviorSubject<CategoryOffer[]> = new BehaviorSubject<CategoryOffer[]>();
    currentSpecialOffers$: Observable<CategoryOffer[]> = this._currentSpecialOffers.asObservable();
    // @ts-ignore
    _currentFeaturedItems: BehaviorSubject<CategoryOffer[]> = new BehaviorSubject<CategoryOffer[]>();
    currentFeaturedItems$: Observable<CategoryOffer[]> = this._currentFeaturedItems.asObservable();
    // @ts-ignore
    _currentNewItems: BehaviorSubject<CategoryOffer[]> = new BehaviorSubject<CategoryOffer[]>();
    currentNewItems$: Observable<CategoryOffer[]> = this._currentNewItems.asObservable();
    // @ts-ignore
    _currentAdvItems: BehaviorSubject<CategoryOffer[]> = new BehaviorSubject<CategoryOffer[]>();
    currentAdvItems$: Observable<CategoryOffer[]> = this._currentAdvItems.asObservable();
    // @ts-ignore
    _futureSpecialOffers: BehaviorSubject<CategoryOffer[]> = new BehaviorSubject<CategoryOffer[]>();
    futureSpecialOffers$: Observable<CategoryOffer[]> = this._futureSpecialOffers.asObservable();
    // @ts-ignore
    _futureFeaturedItems: BehaviorSubject<CategoryOffer[]> = new BehaviorSubject<CategoryOffer[]>();
    futureFeaturedItems$: Observable<CategoryOffer[]> = this._futureFeaturedItems.asObservable();
    // @ts-ignore
    _futureNewItems: BehaviorSubject<CategoryOffer[]> = new BehaviorSubject<CategoryOffer[]>();
    futureNewItems$: Observable<CategoryOffer[]> = this._futureNewItems.asObservable();
    // @ts-ignore
    _futureAdvItems: BehaviorSubject<CategoryOffer[]> = new BehaviorSubject<CategoryOffer[]>();
    futureAdvItems$: Observable<CategoryOffer[]> = this._futureAdvItems.asObservable();

    categoryOfferSource = 'NOT_SET';
    categoryOffer: CategoryOffer | undefined;
    previousCategoryOffer: CategoryOffer | undefined;

    constructor(protected globals: Globals,
                protected dialog: MatDialog,
                protected keycloakMonitorService: KeycloakMonitorService,
                private service: AdminService) {
        super(globals, dialog, keycloakMonitorService);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedParentTabName && changes.selectedParentTabName.currentValue === 'Admin') {
            this.refresh();
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    initComp(): void {
        this.refresh();
    }

    private createCatgoryOffer(dl: Entry<string, CachedDistribution[]>): {
        offer:CategoryOffer,
        featuredFlag: boolean,
        newFlag: boolean,
        advertisedFlag: boolean,
        firstAvailableDate: string} {
        let first = this.getList(dl)[0];
        const {featuredFlag, newFlag, advertisedFlag, firstAvailableDate} = first;
        return {offer:{
            id: this.getListKey(dl),
            description: first.description,
            displayDescription: first.displayDescription,
            altDescription: first.altDescription,
            distributionId: first.id,
            availableDate: first.firstAvailableDate,
            itemCount: this.getList(dl).length,
            detail: false,
            cmsSeqNum: first.cmsSeqNum,
            cmsUrl: handle_null(first.cmsUrl, ''),
            imgSeqNum: first.imgSeqNum,
            itemUrl: handle_null(first.imageUrl, ''),
            category: first.itemSet[0].category.id,
            supplier: first.itemSet[0].supplier.id,
            items: this.buildItemList(this.getList(dl)),
            newFlag: newFlag
        }, advertisedFlag,featuredFlag,newFlag,firstAvailableDate};
    }

    refresh() {
        const callObserver: Observer<CachedDistributionLookup> = {
            next: data => {
                this.distributionLists = create_cached_distribution_entries(data);
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const serviceCall = this.service.getCachedDistributionLists().pipe(take(1));
        const grouped =  serviceCall.pipe(
            map(create_cached_distribution_entries),
            mergeMap(x=>from(x)),
            map( x => this.createCatgoryOffer(x) ),
            groupBy( ({offer, newFlag, advertisedFlag, featuredFlag, firstAvailableDate,}) => {
                let today = (new Date()).toISOString().substring(0, 10);
                if (!newFlag && !advertisedFlag && !featuredFlag) {
                    if (today >= firstAvailableDate) {
                        return 'current_special';
                    } else {
                        return 'future_special';
                    }
                }
                if (featuredFlag) {
                    if (today >= firstAvailableDate) {
                        return 'current_featured';
                    } else {
                        return 'future_featured';
                    }
                }
                if (newFlag) {
                    if (today >= firstAvailableDate) {
                        return 'current_new';
                    } else {
                        return 'future_new';
                    }
                }
                if (advertisedFlag) {
                    if (today >= firstAvailableDate) {
                        return 'current_adv';
                    } else {
                        return 'future_adv';
                    }
                }
                return 'uncategorized';
            })
        );
        grouped.pipe(
            filter(x=> x.key === 'current_special'),
            mergeMap(x=> x.pipe(reduce((a:CategoryOffer[],y)=>[...a,y.offer], []))),
        ).subscribe(data => this._currentSpecialOffers.next(data));
        grouped.pipe(
            filter(x=> x.key === 'current_featured'),
            mergeMap(x=> x.pipe(reduce((a:CategoryOffer[],y)=>[...a,y.offer], []))),
        ).subscribe(data => this._currentFeaturedItems.next(data));
        grouped.pipe(
            filter(x=> x.key === 'current_new'),
            mergeMap(x=> x.pipe(reduce((a:CategoryOffer[],y)=>[...a,y.offer], []))),
        ).subscribe(data => this._currentNewItems.next(data));
        grouped.pipe(
            filter(x=> x.key === 'current_adv'),
            mergeMap(x=> x.pipe(reduce((a:CategoryOffer[],y)=>[...a,y.offer], []))),
        ).subscribe(data => this._currentAdvItems.next(data));
        grouped.pipe(
            filter(x=> x.key === 'future_special'),
            mergeMap(x=> x.pipe(reduce((a:CategoryOffer[],y)=>[...a,y.offer], []))),
        ).subscribe(data => this._futureSpecialOffers.next(data));
        grouped.pipe(
            filter(x=> x.key === 'future_featured'),
            mergeMap(x=> x.pipe(reduce((a:CategoryOffer[],y)=>[...a,y.offer], []))),
        ).subscribe(data => this._futureFeaturedItems.next(data));
        grouped.pipe(
            filter(x=> x.key === 'future_new'),
            mergeMap(x=> x.pipe(reduce((a:CategoryOffer[],y)=>[...a,y.offer], []))),
        ).subscribe(data => this._futureNewItems.next(data));
        grouped.pipe(
            filter(x=> x.key === 'future_adv'),
            mergeMap(x=> x.pipe(reduce((a:CategoryOffer[],y)=>[...a,y.offer], []))),
        ).subscribe(data => this._futureAdvItems.next(data));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }

    getListKey([key, list]: CachedDistributionEntry) {
        return `${key}`;
    }

    getList([key, list]: CachedDistributionEntry): CachedDistribution[] {
        return list;
    }

    private buildItemList(dl: CachedDistribution[]): CategoryOfferItem[] {
        //console.log('dl:', dl);
        let array: CategoryOfferItem[] = [];
        dl.forEach( di => {
            if (di.bundle) {
                di.itemSet.forEach(item => {
                    array.push({
                        id: di.id + '_' + item.id,
                        description: item.description,
                        displayDescription: item.displayDescription,
                        altDescription: item.altDescription,
                        distributionId: di.id,
                        itemNumber: handle_null(item.id, 0),
                        cmsUrl: handle_null(item.cmsUrl, ''),
                        itemUrl: handle_null(item.imageUrl, ''),
                        detail: false,
                        newFlag: di.newFlag,
                        updated: false
                    })
                })
            } else {
                array.push({
                    id: di.key,
                    description: di.itemSet[0].description,
                    displayDescription: di.itemSet[0].displayDescription,
                    altDescription: di.itemSet[0].altDescription,
                    distributionId: di.id,
                    itemNumber: handle_null(di.itemSet[0].id, 0),
                    cmsUrl: handle_null(di.itemSet[0].cmsUrl, ''),
                    itemUrl: handle_null(di.itemSet[0].imageUrl, ''),
                    detail: false,
                    newFlag: di.newFlag,
                    updated: false
                })
            }
        })
        return array;
    }

    showDetail(categoryOffer: CategoryOffer, source: 'CUR_SPECIAL' | 'CUR_FEATURED' | 'CUR_NEW' | 'CUR_ADVERT' | 'FTR_SPECIAL' | 'FTR_FEATURED' | 'FTR_NEW' | 'FTR_ADVERT') {
        this.categoryOfferSource = source;
        this.categoryOffer = categoryOffer;
        if (this.previousCategoryOffer && categoryOffer.id != this.previousCategoryOffer.id) {
            this.previousCategoryOffer.detail = false;
        }
        this.previousCategoryOffer = categoryOffer;
    }

    onOfferChange(categoryOffer: CategoryOffer) {
        let items: CategoryOffer[];
        switch (this.categoryOfferSource) {
            case 'CUR_SPECIAL':
                items = this.updateObservableCategoryOffers(this._currentSpecialOffers.value, categoryOffer);
                this._currentSpecialOffers.next(items);
                break;
            case 'CUR_FEATURED':
                items = this.updateObservableCategoryOffers(this._currentFeaturedItems.value, categoryOffer);
                this._currentFeaturedItems.next(items);
                break;
            case 'CUR_NEW':
                items = this.updateObservableCategoryOffers(this._currentNewItems.value, categoryOffer);
                this._currentNewItems.next(items);
                break;
            case 'CUR_ADVERT':
                items = this.updateObservableCategoryOffers(this._currentAdvItems.value, categoryOffer);
                this._currentAdvItems.next(items);
                break;
            case 'FTR_SPECIAL':
                items = this.updateObservableCategoryOffers(this._futureSpecialOffers.value, categoryOffer);
                this._futureSpecialOffers.next(items);
                break;
            case 'FTR_FEATURED':
                items = this.updateObservableCategoryOffers(this._futureFeaturedItems.value, categoryOffer);
                this._futureFeaturedItems.next(items);
                break;
            case 'FTR_NEW':
                items = this.updateObservableCategoryOffers(this._futureNewItems.value, categoryOffer);
                this._futureNewItems.next(items);
                break;
            case 'FTR_ADVERT':
                items = this.updateObservableCategoryOffers(this._futureAdvItems.value, categoryOffer);
                this._futureAdvItems.next(items);
                break;
        }
    }

    updateObservableCategoryOffers(items: CategoryOffer[], categoryOffer: CategoryOffer): CategoryOffer[] {
        if (items !== undefined) {
            let updatedItems = categoryOffer.items.filter(item => item.updated === true);
            let ucoi: CategoryOfferItem | undefined;
            if (updatedItems.length === 1) {
                ucoi = updatedItems[0];
            }
            if (ucoi !== undefined) {
                items.forEach(catOff => {
                    if (catOff.id === categoryOffer.id) {
                        let list = catOff.items.filter(each => each.updated);
                        if (list !== undefined && list.length > 0) {
                            list.forEach(offer => {
                                if (offer.id === ucoi?.id) {
                                    offer.displayDescription = ucoi?.displayDescription;
                                    offer.altDescription = ucoi?.altDescription;
                                }
                            });
                        }
                    }
                });
            }
        }
        return items;
    }
}
