<div style="padding: 20px;">
    <mat-tab-group (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Application System Parameters">
            <ng-template matTabContent>
                <app-parameters></app-parameters>
            </ng-template>
        </mat-tab>
        <mat-tab label="Cache Refresh Schedule">
            <ng-template matTabContent>
                <app-cache-refresh-schedule></app-cache-refresh-schedule>
            </ng-template>
        </mat-tab>
        <mat-tab label="Distributions">
            <ng-template matTabContent>
                <app-distributions></app-distributions>
            </ng-template>
        </mat-tab>
        <mat-tab label="Distribution Lists">
            <ng-template matTabContent>
                <app-distribution-lists></app-distribution-lists>
            </ng-template>
        </mat-tab>
        <mat-tab label="Item Urls">
            <ng-template matTabContent>
                <app-item-urls></app-item-urls>
            </ng-template>
        </mat-tab>
        <mat-tab label="Tradeshow Groupings">
            <ng-template matTabContent>
                <app-tradeshow-groupings></app-tradeshow-groupings>
            </ng-template>
        </mat-tab>
        <mat-tab label="Trade Show Divisions">
            <ng-template matTabContent>
                <app-tradeshow-divisions></app-tradeshow-divisions>
            </ng-template>
        </mat-tab>
        <mat-tab label="Categories">
            <ng-template matTabContent>
                <app-categories></app-categories>
            </ng-template>
        </mat-tab>
        <mat-tab label="Suppliers">
            <ng-template matTabContent>
                <app-suppliers></app-suppliers>
            </ng-template>
        </mat-tab>
        <mat-tab label="Suppliers By Tradeshow">
            <ng-template matTabContent>
                <app-suppliers-by-tradeshow></app-suppliers-by-tradeshow>
            </ng-template>
        </mat-tab>
        <mat-tab label="Distribution Tradeshows">
            <ng-template matTabContent>
                <app-distributions-by-tradeshow></app-distributions-by-tradeshow>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
