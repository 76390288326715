import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {RestService} from "./rest.service";
import {Observable} from "rxjs";
import {Distribution} from "../model/Distribution";
import {Category} from "../model/Category";
import {CategoryDistributionsLookupTable} from "../model/CategoryDistributionsLookupTable";
import {Globals} from "../globals";

@Injectable({
    providedIn: 'root'
})
export class DistributionService {

    constructor(
        protected globals: Globals,
        protected http: HttpClient,
        private restService: RestService) {}

    getDistribution(distributionKey?: string): Observable<Distribution[]> {
        let getPath = this.globals.getRestBase() + "distribution";
        getPath += `${distributionKey? `/list?key=${distributionKey}`:''}`;
        //console.log("DistributionService getDistribution getPath", getPath);
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<Distribution[]>(getPath, options);
    }

    getDistributions(distributionKey?: string, category?: string, supplier?: string): Observable<Distribution[]> {
        let getPath = this.globals.getRestBase() + "distribution";
        if (distributionKey && (!category && !supplier)) {
            getPath += `${distributionKey ? `/list?key=${distributionKey}` : ''}`;
        } else {
            getPath += `${distributionKey ? `/special?key=${distributionKey}` : ''}`;
            getPath += `${category ? `&category=${category}` : ''}`;
            getPath += `${supplier ? `&supplier=${supplier}` : ''}`;
        }
        //console.log("DistributionService getDistributions getPath", getPath);
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<Distribution[]>(getPath, options);
    }

    getCategoryDistributions(filterBy: string): Observable<CategoryDistributionsLookupTable> {
        let getPath = this.globals.getRestBase() + "distribution/category?filterBy=" + filterBy;
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<CategoryDistributionsLookupTable>(getPath, options);
    }

}
