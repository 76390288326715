<ng-template #tt9Text>
    Enter the desired ship date for the selected Item / Bundle and store list.  Click the link below the ship date field to show all ship dates for the selected Item / Bundle / Store list.
</ng-template>
<div class="ship-date">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <div class="actions">
<!--            <mat-form-field appearance="fill">-->
                <mat-label>Ship Date:</mat-label><br/>
                <input matInput [matDatepicker]="picker"
                   (dateInput)="addEvent('input', $event) //noinspection ValidateTypes"
                   (dateChange)="addEvent('change', $event) //noinspection ValidateTypes"
                   [min]="minShipDate"
                   [max]="maxShipDate"
                   [formControl]="shipDate"
                   [ngbTooltip]="tt9Text"
                   tooltipClass="t9-tooltip"
                   placement="top"
                   triggers="manual"
                   #tt9="ngbTooltip"
                   appTooltip="helpTooltipController"
                   [tooltip]="tt9">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <div class="ship-date-select"
                     (click)="showShipDateSelect()"
                     style="text-decoration: underline; ">
                    <div>{{shipDatesText}}</div>
                </div>
                <app-ship-date-select *ngIf="shipDateSelectVisible$ | async"
                      (shipDateChanged)="onShipDateSelect($event)"
                      [customerFilter]="(customerFilter$ | async) || undefined"></app-ship-date-select>
<!--            </mat-form-field>-->
        </div>
    </div>
</div>
