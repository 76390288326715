<div style="padding: 20px;">
    <p>
        Number of distributions loaded={{distributions.length}}
    </p>
    <ol>
        <li *ngFor="let distribution of distributions">
            <div class="each-distribution">
                {{distribution.key}} - {{distribution.description}}
                <br/>CMS Url:
                <span *ngIf="distribution.cmsUrl!=undefined; else noCmsURL">{{distribution.cmsUrl}}</span>
                <ng-template #noCmsURL><span class="red">not provided by MCTSG12 (FI) or MCTSG16 (SO) {{distribution.key.split('_')[1]=='BUNDLE'?'from the first random Item of the bundle':'for the Item number of ' + distribution.key.split('_')[1]}}</span></ng-template>
                <br/>Dist Image:
                <a *ngIf="distribution.imageUrl!=undefined; else noImageURL" href="{{distribution.imageUrl}}" target="_blank">{{distribution.imageUrl}}</a>
                <ng-template #noImageURL><span class="red">not provided by MCTSG27</span></ng-template>
                <br/>Dist Description:
                {{distribution.displayDescription}}
                <br/>Dist Alt Description:
                {{distribution.altDescription}}
                <br/>Dist Allowance:
                {{distribution.allowance}}
                <br/>Dist Bill Cost:
                {{distribution.billCost}}
                <br/>Dist Ship Dates:
                {{distribution.firstShipDate}} - {{distribution.lastShipDate}}
                <br/>Dist Available Dates:
                {{distribution.firstAvailableDate}} - {{distribution.lastAvailableDate}}
                <br/>Switches:
                <span [ngClass]="[!distribution.advertisedFlag && !distribution.newFlag && !distribution.featuredFlag ? 'highlight-current-promotion' : '']">
                    <span [ngClass]="[distribution.newFlag ? 'bold-flag-true' : '']">NEW</span>&nbsp;
                    <span [ngClass]="[distribution.featuredFlag ? 'bold-flag-true' : '']">FEATURED</span>&nbsp;
                    <span [ngClass]="[distribution.advertisedFlag ? 'bold-flag-true' : '']">ADVERTISED</span>
                </span>
                <hr/>
                <ul *ngIf="distribution.bundle; else notBundleElse">
                    <ul>
                        <li>Bundle Items: {{distribution.itemSet.length}}</li>
                        <ul *ngFor="let item of distribution.itemSet">
                            <li>
                                Item: {{distribution.key}} - {{item.id}} - {{item.upc}} - {{item.description}}
                                <ul>
                                    <li>Category: {{item.category.id}} - {{item.category.name}}</li>
                                    <li>Supplier: {{item.supplier.id}} - {{item.supplier.name}}</li>
                                    <li>Supplier Booth: {{item.supplier.boothId}} - {{item.supplier.boothName}}</li>
                                    <li>
                                        Tradeshow: {{distribution.tradeshow.id}} - {{distribution.tradeshow.name}}&nbsp;
                                        <span *ngIf="distribution.tradeshow.customerSpecific" class="yellow">CUSTOMER SPECIFIC</span>
                                    </li>
                                    <li>Item Bundle Qty: {{item.bundleQty}}</li>
                                    <li>Item Allowance: {{item.allowance}}</li>
                                    <li>Item Bill Cost: {{item.billCost}}</li>
                                    <li>Item Display Description: {{item.displayDescription}}</li>
                                    <li>Item Alt Description: {{item.altDescription}}</li>
                                    <li *ngIf="item.cmsUrl!=undefined; else noCmsURL">CMS Url: {{item.cmsUrl}}</li>
                                    <ng-template #noCmsURL><li>CMS Url: <span class="red">not provided by MCTSG12 for the Item number of {{item.id}}</span></li></ng-template>
                                    <li *ngIf="item.imageUrl!=undefined; else noImageURL">Image URL: <a href="{{item.imageUrl}}" target="_blank">{{item.imageUrl}}</a></li>
                                    <ng-template #noImageURL><span class="red">not provided by MCTSG11</span></ng-template>
                                </ul>
                            </li>
                        </ul>
                    </ul>
                </ul>
                <ng-template #notBundleElse>
                    <ul *ngFor="let item of distribution.itemSet">
                        <li>Item: {{item.id}} - {{item.upc}} - {{item.description}}
                            <ul>
                                <li>Category: {{item.category.id}} - {{item.category.name}}</li>
                                <li>Supplier: {{item.supplier.id}} - {{item.supplier.name}}</li>
                                <li>Supplier Booth: {{item.supplier.boothId}} - {{item.supplier.boothName}}</li>
                                <li>
                                    Tradeshow: {{distribution.tradeshow.id}} - {{distribution.tradeshow.name}}&nbsp;
                                    <span *ngIf="distribution.tradeshow.customerSpecific" class="yellow">CUSTOMER SPECIFIC</span>
                                </li>
                                <li>Item Allowance: {{item.allowance}}</li>
                                <li>Item Bill Cost: {{item.billCost}}</li>
                                <li>Item Display Description: {{item.displayDescription}}</li>
                                <li>Item Alt Description: {{item.altDescription}}</li>
                                <li *ngIf="item.cmsUrl!=undefined; else noCmsURL">CMS Url: {{item.cmsUrl}}</li>
                                <ng-template #noCmsURL><li>CMS Url: <span class="red">not provided by MCTSG12 (FI) or MCTSG16 (SO) for the Item number of {{item.id}}</span></li></ng-template>
                                <li *ngIf="item.imageUrl!=undefined; else noImageURL">Image URL: <a href="{{item.imageUrl}}" target="_blank">{{item.imageUrl}}</a></li>
                                <ng-template #noImageURL><span class="red">not provided by MCTSG11</span></ng-template>
                            </ul>
                    </ul>
                </ng-template>
            </div>
        </li>
    </ol>
</div>
