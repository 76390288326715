import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {TooltipControllerDirective} from "./tooltip-controller.directive";

@Directive({
    selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit {

    @Input() public tooltip!: NgbTooltip;
    @Input("appTooltip") public id!: string;

    constructor() {}

    ngOnInit(): void {
        TooltipControllerDirective.registerToolTip(this.id, this);
    }

    public open() {
        this.tooltip.open();
    }

    public close() {
        this.tooltip.close();
    }

}
