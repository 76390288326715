<div class="admin-actions">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <div style="padding: 5px">
            This feature is only able to refresh the cache of the instance running on the server you are
            currently connected with. You will need to review the Cache Refresh Schedule tab to know if
            You have connected with other instances running on alternate server nodes.
            <div style="padding: 5px">
                <button #refreshButton (click)="refreshCache()">Refresh Cache</button>
            </div>
            <div>Refresh Status:</div>
            <div>{{refreshStatusText}}</div>
        </div>
    </div>
</div>
