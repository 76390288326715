<div class="truck">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <div *ngIf="showEmptyTrucksMessage">There are no items in the truck.</div>
        <div *ngIf="!showEmptyTrucksMessage" class="truck-summary">
            <div class="truck-summary-title">Order Summary</div>
            <div class="truck-summary-total">
                <div class="truck-summary-total-text">Total Cost:</div>
                <div class="truck-summary-total-value">{{truck?.totalCost?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}}</div>
            </div>
            <div class="truck-summary-total">
                <div class="truck-summary-total-text">Total Allow:</div>
                <div class="truck-summary-total-value">{{truck?.totalAllowance?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}}</div>
            </div>
            <div class="truck-summary-total">
                <div class="truck-summary-total-text">Order Total:</div>
                <div class="truck-summary-total-value">{{getOrderTotal()}}</div>
            </div>
            <div class="truck-summary-total">
                <div class="truck-summary-total-text">Total Qty:</div>
                <div class="truck-summary-total-value">{{truck?.totalQuantity}}</div>
            </div>
            <div class="truck-summary-total">
                <div class="truck-summary-total-text" style="margin-top:5px;">Unique Items:</div>
                <div class="truck-summary-total-value">{{truckCount}}</div>
            </div>
            <div class="truck-summary-total">
                <div class="truck-summary-email-text">Email:</div>
                <div class="truck-summary-email-entry"><input id="emailEntry" type="text" style="width: 300px;" [value]="truck?.email?truck?.email:''"/></div>
            </div>
            <button *ngIf="!confirmationMessage" class="truck-button" (click)="completeOrder()">Complete Order</button>
            <div *ngIf="confirmationMessage">{{confirmationMessage}}</div>
        </div>

        <ng-container *ngFor="let distribution of distributionList; index as idx">
            <!-- BEGIN Iterable Table -->
            <table width="100%" border="0" cellspacing="0" cellpadding="4">
                <!-- BEGIN Header Row -->
                <thead>
                    <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt">
                        <td style="max-width: 14%;" class="truck-header-td1">Group / Customer</td>
                        <td style="max-width: 10%;" class="truck-header-td1">Qty (**)</td>
                        <td style="min-width: 300px; max-width: 22%;" class="truck-header-td1">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td class="truck-header-text-stacked">Item/Bundle Description</td></tr>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td class="truck-header-text-stacked">Supplier Name</td></tr>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td style="text-align: center;">UPC</td></tr>
                                </tbody>
                            </table>
                        </td>
                        <td style="max-width: 10%; vertical-align: text-top;">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td class="truck-header-text-stacked">UIN</td></tr>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td class="truck-header-text-stacked">Pack</td></tr>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td style="text-align: center;">Size</td></tr>
                                </tbody>
                            </table>
                        </td>
                        <td style="max-width: 14%;" class="truck-header-td2">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt">
                                    <td style="vertical-align: text-top;">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                            <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td colspan="2" style="text-align: center; white-space: nowrap; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">Ship Date</td></tr>
                                            <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td colspan="2" style="text-align: center; white-space: nowrap; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">Allow</td></tr>
                                            <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td style="text-align: center; white-space: nowrap; border-right-width: 1px; border-right-style: solid; border-right-color: rgb(0, 0, 0);">Start</td><td align="center">End</td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                        <td style="max-width: 14%;" class="truck-header-td1">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt">
                                    <td class="truck-header-text-stacked">
                                        Bill Cost $
                                    </td>
                                </tr>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt">
                                    <td class="truck-header-text-stacked">
                                        Alw $
                                    </td>
                                </tr>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt">
                                    <td style="text-align: center;">Net Cst $</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                        <td style="max-width: 14%; vertical-align: text-top;">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td class="truck-header-text-stacked">Ext Cost $</td></tr>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td class="truck-header-text-stacked">Ext Alw $</td></tr>
                                <tr class="smsstyle_table_shading_hdr smsstyle_hdr_txt"><td style="text-align: center;">Ext Net $</td></tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </thead>
                <!-- END Header Row -->

                <!-- BEGIN Bundle Iterable Rows -->
                <ng-container *ngIf="distribution.bundle">
                    <tr class="smsstyle_table_shading">
                        <td class="smsstyle_table_shading_wht truck-item-text">{{getDistributionGroupName(idx, distribution)}}</td>
                        <td class="smsstyle_table_shading_wht truck-item-text">{{distribution.orderQuantity}}</td>
                        <td class="smsstyle_table_shading_wht truck-item-text">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr><td class="truck-item-text-1">{{distribution.description}}</td></tr>
                                <tr><td style="text-align: center;">{{getDistributionSupplierName(distribution)}}</td></tr>
                                <tr><td style="text-align: center;"></td></tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="smsstyle_table_shading_wht truck-item-text">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr><td style="text-align: center;">&nbsp;</td></tr>
                                <tr><td style="text-align: center;">&nbsp;</td></tr>
                                <tr><td style="text-align: center; white-space: nowrap;">&nbsp;</td></tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="smsstyle_table_shading_wht truck-item-text">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr>
                                    <td style="vertical-align: text-top;">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                            <tr><td colspan="2" style="text-align: center;">&nbsp;</td></tr>
                                            <tr><td colspan="2" style="text-align: center;">&nbsp;</td></tr>
                                            <tr>
                                                <td style="text-align: center; white-space: nowrap;">&nbsp;</td>
                                                <td style="text-align: center; white-space: nowrap;">&nbsp;</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="smsstyle_table_shading_wht truck-item-text">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr><td class="truck-item-text-2">{{getDollar('billCost', distribution, "DIST")}}</td></tr>
                                <tr><td class="truck-item-text-2">{{getDollar('allow', distribution, "DIST")}}</td></tr>
                                <tr><td style="padding-right: 5px; text-align: right;">{{getDollar('netCost', distribution, "DIST")}}</td></tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="smsstyle_table_shading_wht truck-item-text-3">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr><td class="truck-item-text-2">{{getExtValue('extCost', idx, distribution)}}</td></tr>
                                <tr><td class="truck-item-text-2">{{getExtValue('extAllow', idx, distribution)}}</td></tr>
                                <tr><td style="text-align: right; padding-right: 5px;">{{getExtValue('extNet', idx, distribution)}}</td></tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <ng-container *ngFor="let item of distribution.itemSet; index as j">
                        <tr class="smsstyle_table_shading_wht">
                            <td class="smsstyle_table_shading_wht truck-item-text">{{j+1}}</td>
                            <td class="smsstyle_table_shading_wht truck-item-text">{{item.bundleQty}}</td>
                            <td class="smsstyle_table_shading_wht truck-header-td3">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                    <tr>
                                        <td style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">
                                            <div>
                                                <span style="width: 290px">
                                                    <table cellspacing="0" cellpadding="0" border="0">
                                                        <tbody>
                                                        <tr>
                                                            <td><img class="distribution-image" src="{{getItemImageUrl(item)}}"/></td>
                                                            <td>
                                                                <div style="display:inline; text-align: left; font-size: 1.1em; color: #333">
                                                                    <div style="display:block">{{item.description}}</div>
                                                                    <div style="display:inline; font-size: .9em; color: #000">Item #{{item.id}}<span style="color: #a42;">&nbsp;Dist: {{distribution.description}}</span></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td style="text-align: center; border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgb(0, 0, 0);">-</td></tr>
                                    <tr><td style="text-align: center;">{{item.upc}}</td></tr>
                                    </tbody>
                                </table>
                            </td>
                            <td class="smsstyle_table_shading_wht truck-item-text">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                    <tr><td style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">{{distribution.itemSet![0].uin}}</td></tr>
                                    <tr><td style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">{{distribution.itemSet![0].pack}}</td></tr>
                                    <tr><td style="text-align: center; white-space: nowrap;">{{distribution.itemSet![0].size}}</td></tr>
                                    </tbody>
                                </table>
                            </td>
                            <td class="smsstyle_table_shading_wht truck-item-text">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                    <tr>
                                        <td style="vertical-align: text-top;">
                                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                <tbody>
                                                <tr><td colspan="2" style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">{{getDistributionShipDate(idx, distribution)}}</td></tr>
                                                <tr><td colspan="2" style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">Allowance</td></tr>
                                                <tr>
                                                    <td style="text-align: center; white-space: nowrap; border-right-width: 1px; border-right-style: solid; border-right-color: rgb(0, 0, 0);">{{distribution.firstShipDate}}</td>
                                                    <td style="text-align: center; white-space: nowrap;">{{distribution.lastShipDate}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td class="smsstyle_table_shading_wht truck-item-text">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                    <tr><td class="truck-item-col6-text">{{distribution.itemSet![0].billCost}}</td></tr>
                                    <tr><td class="truck-item-col6-text">{{distribution.itemSet![0].allowance}}</td></tr>
                                    <tr><td style="padding-right: 5px; text-align: right;">{{(distribution.itemSet![0].billCost - distribution.itemSet![0].allowance).toLocaleString()}}</td></tr>
                                    </tbody>
                                </table>
                            </td>
                            <td class="smsstyle_table_shading_wht truck-item-text-4">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                    <tr><td style="text-align: right; padding-right: 5px;">&nbsp;</td></tr>
                                    <tr><td style="text-align: right; padding-right: 5px;">&nbsp;</td></tr>
                                    <tr><td style="text-align: right; padding-right: 5px;">&nbsp;</td></tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <!-- END Bundle Iterable Rows -->

                <!-- BEGIN Item Iterable Rows -->
                <ng-container *ngIf="!distribution.bundle">
                    <tr class="smsstyle_table_shading_wht">
                    <td class="smsstyle_table_shading_wht truck-item-text">{{getDistributionGroupName(idx, distribution)}}</td>
                    <td class="smsstyle_table_shading_wht truck-item-text">{{distribution.orderQuantity==0?'-':distribution.orderQuantity}}</td>
                    <td class="smsstyle_table_shading_wht truck-header-td3">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                            <tr>
                                <td style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">
                                    <div>
                                        <span style="width: 290px">
                                            <table cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                <tr>
                                                    <td><img class="distribution-image" src="{{getDistributionImageUrl(distribution)}}"/></td>
                                                    <td>
                                                        <div style="display:inline; text-align: left; font-size: 1.1em; color: #333">
                                                            <div style="display:block">{{distribution.itemSet![0].description}}</div>
                                                            <div style="display:inline; font-size: .9em; color: #000">Item #{{distribution.itemSet![0].id}}<span style="color: #a42;">&nbsp;Dist: {{distribution.description}}</span></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr><td style="text-align: center; border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgb(0, 0, 0);">{{distribution.itemSet![0].supplier?.name}}</td></tr>
                            <tr><td style="text-align: center;">{{distribution.itemSet![0].upc}}</td></tr>
                            </tbody>
                        </table>
                    </td>
                    <td class="smsstyle_table_shading_wht truck-item-text">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                            <tr><td style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">{{distribution.itemSet![0].uin}}</td></tr>
                            <tr><td style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">{{distribution.itemSet![0].pack}}</td></tr>
                            <tr><td style="text-align: center; white-space: nowrap;">{{distribution.itemSet![0].size}}</td></tr>
                            </tbody>
                        </table>
                    </td>
                    <td class="smsstyle_table_shading_wht truck-item-text">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                            <tr>
                                <td style="vertical-align: text-top;">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tbody>
                                        <tr><td colspan="2" style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">{{getDistributionShipDate(idx, distribution)}}</td></tr>
                                        <tr><td colspan="2" style="text-align: center; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">Allowance</td></tr>
                                        <tr>
                                            <td style="text-align: center; white-space: nowrap; border-right-width: 1px; border-right-style: solid; border-right-color: rgb(0, 0, 0);">{{distribution.firstShipDate}}</td>
                                            <td style="text-align: center; white-space: nowrap;">{{distribution.lastShipDate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td class="smsstyle_table_shading_wht truck-item-text">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                            <tr><td class="truck-item-col6-text">{{getDollar('billCost', distribution, "ITEM")}}</td></tr>
                            <tr><td class="truck-item-col6-text">{{getDollar('allow', distribution, "ITEM")}}</td></tr>
                            <tr><td style="padding-right: 5px; text-align: right;">{{getDollar('netCost', distribution, "ITEM")}}</td></tr>
                            </tbody>
                        </table>
                    </td>
                    <td class="smsstyle_table_shading_wht truck-item-text-4">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                            <tr><td style="text-align: right; padding-right: 5px; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">{{getExtValue('extCost', idx, distribution)}}</td></tr>
                            <tr><td style="text-align: right; padding-right: 5px; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(0, 0, 0);">{{getExtValue('extAllow', idx, distribution)}}</td></tr>
                            <tr><td style="text-align: right; padding-right: 5px;">{{getExtValue('extNet', idx, distribution)}}</td></tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </ng-container>
                <!-- END Item Iterable Rows -->
            </table>
            <!-- END Iterable Table -->
            <table width="100%" border="0" cellspacing="0" cellpadding="4">
                <tr>
                    <td style="padding-top: 2px; padding-bottom: 2px;">
                        <button class="truck-detail-button" (click)="allocateClicked(idx, distribution)">Allocate</button>
                        <button class="truck-detail-button" style="margin-left: 5px;" (click)="removeFromTruckClicked(idx, distribution)">Remove</button>
                        <div *ngIf="distribution.allocated" style="display: inline-block;">
                            <button class="truck-detail-button" style="margin-left: 5px;" (click)="toggleShowStores(idx, distribution)">{{getShowStoresButtonText(distribution)}}</button>
                            <div style="display: inline-block; margin-left: 5px;">Number of Stores: {{getNumberOfAllocatedStoresForDist(idx, distribution)}}</div>
                        </div>
                    </td>
                </tr>
            </table>
            <!-- BEGIN Iterable Allocated Stores Table -->
            <table *ngIf="canShowStores(idx, distribution)" width="100%" border="0" cellspacing="0" cellpadding="4">
                <ng-container *ngFor="let store of getDistributionStores(idx, distribution)">
                    <tr name="3" height="20"><td width="100px"></td><td style="text-align: center;">{{store.orderQuantity}}</td><td colspan="5">{{getStoreDivision(store)}} - {{store.oldCust}} - {{store.storeName}}</td></tr>
                </ng-container>
            </table>
            <!-- END Iterable Allocated Store Table -->
        </ng-container>
        <br/><br/>
        <div *ngIf="!showEmptyTrucksMessage">
            <div>Bill Cost and Net Cost do not include McLane Markup or Tax.</div>
            <div>** Quantity is for ALL stores.</div>
        </div>
        <br/><br/><br/><br/>
    </div>
</div>


