import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {CustomerOrderQuantity} from "../../../shared/model/CustomerOrderQuantity";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {EMPTY, Observable, Observer, Subject} from "rxjs";
import {AllocateService} from "../../../shared/services/allocate.service";
import {CustomerFilter} from "../../../shared/model/CustomerFilter";
import {map, startWith, take} from "rxjs/operators";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {AllocateComponent} from "../../allocate/allocate.component";
import {InactiveCustomersComponent} from "../inactive-customers/inactive-customers.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../shared/services/dialog.service";

type CustomerFilterChange = { previousValue?: CustomerFilter, firstChange: boolean, currentValue?: CustomerFilter };

@Component({
    selector: 'app-store-allocate',
    templateUrl: './store-allocate.component.html',
    styleUrls: ['./store-allocate.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreAllocateComponent implements OnInit, OnChanges, AfterViewInit {

    loading$: Observable<boolean> = EMPTY;

    @Input() public customerFilter?: CustomerFilter;
    @ViewChild(MatTable) table!: MatTable<any>;

    // @ts-ignore
    @ViewChild(MatSort) sort: MatSort;

    @ViewChild('tt10', {static: false}) tt10: NgbTooltip | undefined;
    @ViewChild('tt11', {static: false}) tt11: NgbTooltip | undefined;
    @ViewChild('tt12', {static: false}) tt12: NgbTooltip | undefined;

    displayedColumns: string[] = [
        'select',
        'orderQty',
        'mcLaneDiv',
        'customerNumber',
        'storeNumber',
        'storeName',
        'address',
        'city',
        'state'];
    customerOrderQuantities = new MatTableDataSource<CustomerOrderQuantity>();
    inactiveCustomers: CustomerOrderQuantity[] = [];
    selection = new SelectionModel<CustomerOrderQuantity>(true, []);

    activeCustomerCount: number = 0;
    activeCustomerCountText: string = "";
    inactiveCustomerCount: number = 0;
    inactiveCustomerCountText: string = "";

    constructor(
        private allocateService: AllocateService,
        private dialogService: DialogService,
        public dialog: MatDialog,
        private changeDetectorRefs: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.customerOrderQuantities.sort = this.sort;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.customerOrderQuantities.data.forEach(row => this.selection.select(row));
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.customerOrderQuantities.data.length;
        return numSelected === numRows;
    }

    checkboxLabel(row?: CustomerOrderQuantity): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
    }


    plural( value: number,  text: string): string {
        return text + (value>1 ? 's': '');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customerFilter) {
            //console.log("StoreAllocateComponent customerFilter changed:", changes.customerFilter);
            const {previousValue, firstChange, currentValue}: CustomerFilterChange = changes.customerFilter;
            if (currentValue &&
                currentValue.customerGroupType &&
                currentValue.customerGroupId &&
                currentValue.distributionId &&
                currentValue.shipDate
            ) {
                //console.log("StoreAllocateComponent customerFilter currentValue:", currentValue);
                const observer: Observer<CustomerOrderQuantity[]> = {
                    next: data => {
                        if (data) {
                            //console.log("data: ", data);
                            let activeCustomers: CustomerOrderQuantity[] = [];
                            let [active, inactive] = [0,0];
                            for (let coq of data) {
                                if (coq.status?.match("ACT") !== null) {
                                    active = active + 1;
                                    activeCustomers.push(coq);
                                }
                                if (coq.status?.match("ACT") === null) {
                                    inactive = inactive + 1;
                                    this.inactiveCustomers.push(coq);
                                }
                            }
                            this.customerOrderQuantities = new MatTableDataSource<CustomerOrderQuantity>(activeCustomers);
                            this.customerOrderQuantities.sort = this.sort; // This location is critical!
                            this.activeCustomerCount = active;
                            this.inactiveCustomerCount = inactive;

                            this.changeDetectorRefs.detectChanges();
                        }
                    },
                    error: _ => {
                    },
                    complete: () => {
                    }
                };
                const serviceCall = this.allocateService.getCustomerOrderQuantities(this.customerFilter!).pipe(take(1));
                this.loading$ = serviceCall.pipe(map(_=>false), startWith(true));
                serviceCall.subscribe(observer);
            }
        }
    }

    applyQtyToAllAction() {
        let qtyToAllEntry: HTMLInputElement = <HTMLInputElement> document.getElementById('apply-qty-to-all-entry');
        if (qtyToAllEntry && qtyToAllEntry.value.trim().length < 1) {
            this.dialogService.alert({
                title: "Apply Qty to Selected",
                message: "Please enter a valid quantity",
                confirmText: "Ok"
            }).subscribe(confirmed => {
                if (confirmed) {
                    qtyToAllEntry.focus();
                }
            });
            return;
        }
        if (qtyToAllEntry && this.selection.isEmpty()) {
            this.dialogService.alert({
                title: "Apply Qty to Selected",
                message: "Please select one or more stores to apply mass quantity",
                confirmText: "Ok"
            });
            return;
        }
        this.selection.selected.forEach(coq => {
            //console.log("coq: ", coq);
            coq.orderQuantity = parseInt(qtyToAllEntry.value.trim());
        });
        //console.log("this.selection.selected: ", this.selection.selected);
        const serviceCall = this.allocateService.updateTruck(this.customerFilter, this.selection.selected).pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_=>false), startWith(true));
    }

    quantityChanged($event: Event, coq: CustomerOrderQuantity) {
        //console.log("quantityChanged: ", $event);
        //console.log("change value: ", ($event.target as HTMLInputElement).value);
        // selectedCustomerGroupId
        coq.orderQuantity = parseInt(($event.target as HTMLInputElement).value);
        let division = {
            mcLaneDiv:this.customerFilter?.mcLaneDiv,
            divPart:(this.customerFilter?.divPart ? this.customerFilter?.divPart : 0)
        }
        let custGrp = {
            groupName: this.customerFilter?.customerGroupId,
            groupType: this.customerFilter?.customerGroupType,
            division: division
        };
        coq.customerGroup = custGrp;
        let coqList: CustomerOrderQuantity[] = [];
        //console.log("coq: ",coq);
        coqList.push(coq);
        const serviceCall = this.allocateService.updateTruck(this.customerFilter, coqList).pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_=>false), startWith(true));
    }

    applyToOther() {
        if (this.selection.isEmpty()) {
            this.dialogService.alert({
                title: "Apply To Other Ordered Items",
                message: "Please select stores to apply changes.",
                confirmText: "Ok"
            });
            return;
        }
    }

    showInactiveCustomers() {
        let dialogRef = this.dialog.open(InactiveCustomersComponent, {
            height: '400px',
            width: '600px',
        });
        let inactiveCustDialog = dialogRef.componentInstance;
        inactiveCustDialog.inactiveCustomers = this.inactiveCustomers;
    }
}
