import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {CustomerGroupType, CustomerGroupTypeKey} from "../../../shared/model/CustomerGroupType";
import {BehaviorSubject, EMPTY, Observable, Observer, Subject} from "rxjs";
import {map, startWith, take} from "rxjs/operators";
import {CustomerService} from "../../../shared/services/customer.service";
import {LoginType} from "../../../shared/model/LoginType";
import {CustomerGroup} from "../../../shared/model/CustomerGroup";
import {DomainEntry} from "../../../shared/model/DomainEntry";
import {Division} from "../../../shared/model/Division";
import {CustomerFilter} from "../../../shared/model/CustomerFilter";
import {MatTable} from "@angular/material/table";
import {MatSelect} from "@angular/material/select";
import {UserService} from "../../../shared/services/user.service";
import {DialogService} from "../../../shared/services/dialog.service";

@Component({
    selector: 'app-customer-select',
    templateUrl: './customer-select.component.html',
    styleUrls: ['./customer-select.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerSelectComponent implements OnInit {

    loading$: Observable<boolean> = EMPTY;

    @ViewChild('tt4', {static: false}) tt4: NgbTooltip | undefined;
    @ViewChild('tt5', {static: false}) tt5: NgbTooltip | undefined;
    @ViewChild('tt6', {static: false}) tt6: NgbTooltip | undefined;
    @ViewChild('tt7', {static: false}) tt7: NgbTooltip | undefined;
    @ViewChild('tt8', {static: false}) tt8: NgbTooltip | undefined;

    @ViewChild('customerGroupSelect') cgSelect!: MatSelect;

    @Output() customerFilterChanged = new EventEmitter<CustomerFilter>();

    loginType = LoginType.SUP;

    private customerGroupType: CustomerGroupTypeKey = 'DRACO';
    groupingText: string = "Customer Grouping";
    private groupSelectVisibleSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
    public groupSelectVisible$: Observable<boolean> = this.groupSelectVisibleSubject.asObservable();

    customerGroups: DomainEntry[] = [];
    customerGroupsMap = new Map();
    selectedCustomerGroupId: any;

    divisions: Division[] = [];
    allDiv: Division = {
        divPart: 0,
        mcLaneDiv: "MC",
        userDivName: "ALL"
    };
    selectedDivision: string = this.allDiv.mcLaneDiv!;

    workLists: DomainEntry[] = [];
    selectedWorklist: string = "";

    constructor(private customerService: CustomerService,
                private dialogService: DialogService,
                protected userService: UserService,) {}

    ngOnInit(): void {
        this.loadCustomerGroupSelect(this.loginType);
        //this.initUser();
    }

    initUser() {
        //console.log("initializing user");
        this.userService.getUserId()
            .pipe(take(1))
            .subscribe(user => {
                //console.log("user service result: ", user);
            });
    }

    showCustomerSelect() {
        this.groupSelectVisibleSubject.next(true);
    }

    public onCustomerGroupTypeSelect(type: CustomerGroupTypeKey): void {
        this.groupSelectVisibleSubject.next(false);
        this.customerGroupType = type;
        this.groupingText = CustomerGroupType[type];
        this.loadCustomerGroupSelect(this.loginType);
    }

    private loadCustomerGroupSelect(loginType: LoginType) {
        this.clear();
        const observer: Observer<CustomerGroup[]> = {
            next: data => {
                //console.log("data={}", data);
                if (data) {
                    for (let customerGroup of data) {
                        //console.log("customerGroup.division={}", customerGroup.division);
                        if (this.customerGroupsMap.get(customerGroup.groupName) == undefined) {
                            let arr: CustomerGroup[] = [];
                            arr.push(customerGroup);
                            this.customerGroupsMap.set(customerGroup.groupName, arr);
                            if (customerGroup.groupName && customerGroup.groupDescription) {
                                let option: DomainEntry = {
                                    value: customerGroup.groupName,
                                    display: customerGroup.groupDescription
                                };
                                this.customerGroups.push(option);
                            }
                        } else {
                            let arr: CustomerGroup[] = this.customerGroupsMap.get(customerGroup.groupName);
                            arr.push(customerGroup);
                        }
                    }
                    this.customerGroups.sort((o1, o2) => (o1.display > o2.display) ? 1: -1);
                    if (this.customerGroups.length > 0) {
                        this.selectedCustomerGroupId = this.customerGroups[0].value;
                        //console.log("CustomerSelectComponent this.selectedCustomerGroupId:", this.selectedCustomerGroupId);
                        this.customerGroupSelectChanged();
                    }
                } else {
                    this.dialogService.alert({
                        title: "User Setup",
                        message: "No customer groups are found for the logged in user.",
                        confirmText: "Ok"
                    });
                }
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const serviceCall = this.customerService.getCustomerGroups(this.customerGroupType, loginType)
            .pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_=>false), startWith(true));
        serviceCall.subscribe(observer);
    }

    customerGroupSelectChanged() {
        //console.log("selectedCustomerGroup={}", this.selectedCustomerGroupId);
        let groups: CustomerGroup[] = this.customerGroupsMap.get(this.selectedCustomerGroupId);
        this.divisions.push(this.allDiv);
        for (let group of groups) {
            if (group.division) {
                this.divisions.push(group.division);
            }
        }
        this.selectedDivision = this.allDiv.mcLaneDiv!;
        this.notifyCustomerFilterChanged();
    }

    divisionSelectChanged() {
        this.notifyCustomerFilterChanged();
    }

    worklistSelectChanged() {

    }

    worklistSelect() {

    }

    clear() {
        this.customerGroups = [];
        this.customerGroupsMap = new Map();
        this.divisions = [];
        this.workLists = [];
    }

    notifyCustomerFilterChanged() {
        let divPrt = 0;
        this.divisions.forEach( d => {
            if (d.mcLaneDiv == this.selectedDivision) {
                divPrt = d.divPart ? d.divPart : 0;
            }
        });
        let tempFilter: CustomerFilter = {
            loginType: this.loginType,
            customerGroupType: this.customerGroupType,
            customerGroupId: this.selectedCustomerGroupId,
            mcLaneDiv: this.selectedDivision,
            divPart: divPrt
        };
        //console.log("CustomerSelectComponent notifyCustomerFilterChanged tempFilter=", tempFilter);
        this.customerFilterChanged.emit(tempFilter);
    }
}
