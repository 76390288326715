import { Injectable, isDevMode } from '@angular/core';
import { RestService} from './rest.service';
import {ApusUser} from "../model/apus-user";
import {Observable, Subject} from "rxjs";

@Injectable()
export class ApusUserService {
	public userSource = new Subject<ApusUser>();
	public current_user: ApusUser | undefined;
	public user$ = this.userSource.asObservable();

	constructor(private restService: RestService) {}

	login(user: String): Observable<Object> {
		if ( isDevMode() ) {
			//console.log( 'Logging in as', user );
		}
		let parm_user: String = '';
		if (user !== undefined) {
			parm_user = '?user=' + user;

		}
        //console.log("ApusUserService user ", parm_user);
        return this.restService.get(`user${parm_user}`);
	}

	setUser(user: ApusUser) {
		this.userSource.next(user);
	}
}
