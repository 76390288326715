import {Component, OnInit} from '@angular/core';
import {TruckService} from "../../../shared/services/truck.service";
import {EMPTY, Observable, Observer, timer} from "rxjs";
import {map, startWith, take} from "rxjs/operators";
import {KeycloakOptions, KeycloakService} from "keycloak-angular";
import {KeycloakLoginOptions} from "keycloak-js";
import {KeycloakMonitorService} from "../../../shared/services/keycloak-monitor.service";
import {Globals} from "../../../shared/globals";
import {MatDialog} from "@angular/material/dialog";
import {BaseComponent} from "../../base/base.component";

@Component({
    selector: 'app-truck-count',
    templateUrl: './truck-count.component.html',
    styleUrls: ['./truck-count.component.css']
})
export class TruckCountComponent extends BaseComponent implements OnInit {

    loading$: Observable<boolean> = EMPTY;

    truckCount = 0;

    constructor(
        protected globals: Globals,
        public dialog: MatDialog,
        private truckService: TruckService,
        private keycloak: KeycloakService,
        protected keycloakMonitorService: KeycloakMonitorService) {
        super(globals, dialog, keycloakMonitorService);
    }

    ngOnInit(): void {
        //console.log("ngOnInit");
        super.ngOnInit();
    }

    initComp() {
        //console.log("Initializing Component");
        this.getTruckCount();
    }

    getTruckCount() {
        const callbserver: Observer<any> = {
            next: data => {
                //console.log("truck count data:", data);
                if (data.itemsInTruck) {
                    this.truckCount = data.itemsInTruck;
                    //console.log("this.truckCount:", this.truckCount);
                }
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const truckServiceCall = this.truckService.getTruckCount().pipe(take(1));
        this.loading$ = truckServiceCall.pipe(map(_ => false), startWith(true));
        truckServiceCall.subscribe(callbserver);
    }

}
