import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApplicationSystemParameter} from "../../../../shared/model/ApplicationSystemParameter";
import {AdminService} from "../../../../shared/services/admin.service";
import {Subject} from "rxjs";
import {take} from "rxjs/operators";

@Component({
    selector: 'app-cache-refresh-schedule',
    templateUrl: './cache-refresh-schedule.component.html',
    styleUrls: ['./cache-refresh-schedule.component.css']
})
export class CacheRefreshScheduleComponent implements OnInit, OnDestroy {

    private destroy$: Subject<void> | undefined;

    cacheId: string = 'bogus id';
    public cacheRefreshTimestamps: string[] = [];
    public cacheMonitorTimestamps: string[] = [];

    constructor(private service: AdminService) {
        //console.log("CacheRefreshScheduleComponent.constructor() called");

        service.getCacheId().pipe(take(1)).subscribe(data => {
            //console.log("data={}", data);
            this.cacheId = data;
        });

        service.getRefreshTimeStampList().pipe(take(1)).subscribe(cacheTimestamps => {
            this.cacheRefreshTimestamps = cacheTimestamps as string[];
        });

        service.getMonitorTimeStampList().pipe(take(1)).subscribe(cacheTimestamps => {
            this.cacheMonitorTimestamps = cacheTimestamps as string[];
        });
    }

    ngOnInit(): void {
        this.destroy$ = new Subject<void>();
    }

    ngOnDestroy(): void {
        // cleanup ANY observable that does .pipe(takeUntil(this.destroy))
        //this.destroy$.next();
        //this.destroy$.complete();
        // cleanup constructor subscription
        //this.cacheId.unsubscribe();
    }

}
