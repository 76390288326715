import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suppliers-by-tradeshow',
  templateUrl: './suppliers-by-tradeshow.component.html',
  styleUrls: ['./suppliers-by-tradeshow.component.css']
})
export class SuppliersByTradeshowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
