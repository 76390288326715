import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit, Output
} from '@angular/core';
import {CategoryOffer} from "../../../../shared/model/CategoryOffer";
import {EMPTY, Observable, Observer, Subject} from "rxjs";
import {map, startWith, take} from "rxjs/operators";
import {AdminService} from "../../../../shared/services/admin.service";
import {UrlMaint} from "../../../../shared/model/UrlMaint";
import {CategoryOfferItem} from "../../../../shared/model/CategoryOfferItem";
import {handle_null} from "../../../../shared/util/ObjectUtil";

@Component({
    selector: 'app-url-maint-edit',
    templateUrl: './url-maint-edit.component.html',
    styleUrls: ['./url-maint-edit.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlMaintEditComponent implements OnInit {

    loading$: Observable<boolean> = EMPTY;

    // @ts-ignore
    @Input() urlFor: string = 'special' | 'featured' | 'new';
    // @ts-ignore
    @Input() categoryOffer: CategoryOffer;
    categoryOfferItem!: CategoryOfferItem;
    @Output() offerChanged: EventEmitter<CategoryOffer> = new EventEmitter<CategoryOffer>();

    itemCmsValue!: string;
    itemImgValue!: string;
    itemDescValue!: string;
    itemAltDescValue!: string;

    @Input() previousCategoryOffer: CategoryOffer | undefined;
    previousCategoryOfferItem: CategoryOfferItem | undefined;
    private saveMessageSubject = new Subject<string>();
    public saveMessage$ = this.saveMessageSubject.asObservable();
    private saveErrorMessageSubject = new Subject<string>();
    public saveErrorMessage$ = this.saveErrorMessageSubject.asObservable();

    constructor(private service: AdminService) {
    }

    ngOnInit(): void {
        //console.log(this.urlFor);
    }

    toggleDetail() {
        this.saveErrorMessageSubject.next('');
        let toggle = !this.categoryOffer.detail;
        if (this.previousCategoryOffer) {
            this.previousCategoryOffer.detail = !toggle;
        }
        this.categoryOffer.detail = toggle;
        this.previousCategoryOffer = this.categoryOffer;
    }

    getCMSLink(): string {
        if (this.categoryOffer) {
            let target = this.categoryOffer.cmsUrl;
            if (target?.length) {
                if (this.urlFor == 'special') {
                    return target + "?key=" + this.categoryOffer.id.split('_')[0] +
                        "&category=" + this.categoryOffer.category +
                        "&supplier=" + this.categoryOffer.supplier;
                } else {
                    return target + "?key=" + this.categoryOffer.id;
                }
            }
        }
        return '';
    }

    urlChanged(source: string, $event: Event) {
        let value = (<HTMLInputElement>$event.target).value;
        if (source == 'cms') {
            this.categoryOffer.cmsUrl = value;
        } else {
            this.categoryOffer.itemUrl = value;
        }
    }

    itemUrlChanged(source: string, item: CategoryOfferItem, $event: Event) {
        let value = (<HTMLInputElement>$event.target).value;
        if (source == 'cms') {
            item.cmsUrl = value;
            this.itemCmsValue = value;
        } else {
            item.itemUrl = value;
            this.itemImgValue = value;
        }
    }

    save(level: string) {
        //console.log("save");
        this.saveErrorMessageSubject.next('');
        let cmsUrlVal, imgUrlVal, descVal, altDecVal: string | undefined;
        if (level === 'dist') {
            cmsUrlVal = this.categoryOffer.cmsUrl;
            imgUrlVal = this.categoryOffer.itemUrl;
            descVal = this.categoryOffer.displayDescription;
            altDecVal = this.categoryOffer.altDescription;
        } else {
            cmsUrlVal = this.itemCmsValue;
            imgUrlVal = this.itemImgValue;
            descVal = this.itemDescValue;
            altDecVal = this.itemAltDescValue;
        }
        let updatedItems = this.categoryOffer.items.filter(item => item.updated === true);
        let ucoi: CategoryOfferItem | undefined;
        if (updatedItems.length === 1) {
            ucoi = updatedItems[0];
            ucoi.displayDescription = descVal;
            ucoi.altDescription = altDecVal;
            ucoi.updated = true;
        }
        let urlMaint: UrlMaint = {
            distributionId: this.categoryOffer.distributionId,
            itemNumber: this.categoryOfferItem.itemNumber,
            categoryCode: this.categoryOffer.category,
            supplierId: this.categoryOffer.supplier,
            description: descVal,
            altDescription: altDecVal,
            url: cmsUrlVal,
            imageUrl: imgUrlVal,
            newFlag: this.categoryOffer.newFlag
        };
        //console.log("urlMaint:", urlMaint);
        const callObserver: Observer<any> = {
            next: data => {
                //console.log("data:", data);
                this.saveMessageSubject.next('Changes Saved!');
                setTimeout(() => this.saveMessageSubject.next(''), 3000);
                this.categoryOffer.items.forEach(item => {
                    if (item.id === handle_null(this.previousCategoryOfferItem?.itemNumber, "0")) {
                        item.updated = true;
                    }
                });
                this.offerChanged.emit(this.categoryOffer);
            },
            error: _ => {
                this.saveErrorMessageSubject.next('An Error has occurred! Changes may not be saved.');
            },
            complete: () => {
            }
        };
        const serviceCall = this.service.saveUrl(urlMaint).pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }

    toggleItemDetail(categoryOfferItem: CategoryOfferItem) {
        this.categoryOfferItem = categoryOfferItem;
        this.itemCmsValue = ''+categoryOfferItem.cmsUrl;
        this.itemImgValue = ''+categoryOfferItem.itemUrl;
        this.itemDescValue = ''+categoryOfferItem.description;
        this.itemAltDescValue = ''+categoryOfferItem.altDescription;
        let toggle = !categoryOfferItem.detail;
        if (this.previousCategoryOfferItem) {
            this.previousCategoryOfferItem.detail = !this.previousCategoryOfferItem.detail;
        }
        categoryOfferItem.detail = toggle;
        this.previousCategoryOfferItem = categoryOfferItem;
    }

    descChanged($event: Event) {
        this.categoryOffer.displayDescription = (<HTMLInputElement>$event.target).value;
    }

    altDescChanged($event: Event) {
        this.categoryOffer.altDescription = (<HTMLInputElement>$event.target).value;
    }

    itemDescChanged(item: CategoryOfferItem, $event: Event) {
        this.itemDescValue = (<HTMLInputElement>$event.target).value;
        item.updated = true;
    }

    itemAltDescChanged(item: CategoryOfferItem, $event: Event) {
        this.itemAltDescValue = (<HTMLInputElement>$event.target).value;
        item.updated = true;
    }

    getItemCount(categoryOffer: CategoryOffer) {
        return categoryOffer.items.length;
    }

    getCMSItemLink(item: CategoryOfferItem): string {
        let target = item.cmsUrl;
        if (target?.length) {
            if (this.urlFor == 'special') {
                return target + "?key=" + this.categoryOffer.id.split('_')[0] +
                    "&category=" + this.categoryOffer.category +
                    "&supplier=" + this.categoryOffer.supplier;
            } else {
                return target + "?key=" + this.categoryOffer.id;
            }
        }
        return '';
    }
}
