<div class="allocate">
    <span style="margin-left: 10px;font-weight: bold;">Order Allocate</span><app-help></app-help>
    <div class="allocate-content">
        <div class="top-section">
            <div class="top-row1">
                <div class="top-row1-column1"><app-item-detail
                                                    [distribution]="distribution"
                                                    (distributionChanged)="updateDistribution($event)"></app-item-detail></div>
                <div class="top-row1-column2">
                    <div class="actions">
                        <button (click)="close()">Close</button><br/>
                        <button (click)="reviewOrder()">Review Order</button><br/>
                        <ng-container *ngIf="!isEdgeHost()">
                            <a #truckLink style="visibility: hidden;" target="_parent">Truck Link</a>
                        </ng-container>
                        <ng-container *ngIf="isEdgeHost()">
                            <a #truckLink style="visibility: hidden;" target="_parent" href="{{reviewOrderLink}}">Truck Link</a>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="top-row2">
                <div class="top-row2-column1"><app-customer-select
                                                    (customerFilterChanged)="customerFilterChanged($event)"></app-customer-select></div>
                <div class="top-row2-column2"><app-ship-date
                                                    [distribution]="distribution"
                                                    (shipDateChanged)="shipDateChanged($event)"
                                                    (customerFilterChanged)="customerFilterChanged($event)"
                                                    [customerFilter]="(customerFilter$ | async) || undefined"></app-ship-date></div>
            </div>
        </div>
        <div class="bottom-section"><app-store-allocate [customerFilter]="(customerFilter$ | async) || undefined"></app-store-allocate></div>
    </div>
</div>
