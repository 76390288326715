import {Component, OnInit} from '@angular/core';
import {MatTabChangeEvent} from "@angular/material/tabs";

@Component({
    selector: 'app-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    tabChanged($event: MatTabChangeEvent) {
        //console.log("tabChanged", $event)
    }
}
