<li [ngClass]="!categoryOffer.cmsUrl || !categoryOffer.itemUrl ? 'highlight' :''">
    <div (click)="toggleDetail()">{{categoryOffer.id}} - {{categoryOffer.description}} - {{categoryOffer.availableDate}} - {{categoryOffer.supplier}} - {{categoryOffer.category}} ({{getItemCount(categoryOffer)}})</div>
    <ng-container *ngIf="categoryOffer.detail">
        <div class="detail">
<!--            <ng-container>-->
<!--                <div class="edit">-->
<!--                    <div><div style="width: 100px;display: inline-block;">Description</div><input #descInput type="text" [value]="categoryOffer.displayDescription?categoryOffer.displayDescription:''" (change)="descChanged($event)"/></div>-->
<!--                    <div><div style="width: 100px;display: inline-block;">Alt Description</div><input #altDescInput type="text" [value]="categoryOffer.altDescription?categoryOffer.altDescription:''" (change)="altDescChanged($event)"/></div>-->
<!--                    <div><div style="width: 100px;display: inline-block;">CMS Link</div><input #cmsInput type="text" [value]="categoryOffer.cmsUrl" (change)="urlChanged('cms', $event)"/></div>-->
<!--                    <ng-container *ngIf="categoryOffer.cmsUrl">-->
<!--                        <div class="link">{{getCMSLink()}}</div>-->
<!--                        <button class="clipboard" [cdkCopyToClipboard]="getCMSLink()">Copy link to Clipboard</button>-->
<!--                    </ng-container>-->
<!--                    <div><div style="width: 100px;display: inline-block;">Image Link</div><input #imgInput type="text" [value]="categoryOffer.itemUrl" (change)="urlChanged('img', $event)"/></div>-->
<!--                    <button (click)="save('dist')">Save</button>-->
<!--                    <div style="padding: 5px; color: green;display: grid;align-items: center;">{{saveMessage$|async}}</div>-->
<!--                    <div style="padding: 5px; color: red;display: grid;align-items: center;">{{saveErrorMessage$|async}}</div>-->
<!--                </div>-->
<!--            </ng-container>-->
            <div class="detail-items">
                Items of Offer
                <ol>
                    <ng-container *ngFor="let item of categoryOffer.items">
                        <li>
                            <div (click)="toggleItemDetail(item)">{{item.id}} - {{item.description}}</div>
                            <ng-container *ngIf="item.detail">
                                <div class="edit">
                                    <div><div style="width: 100px;display: inline-block;">Description</div><input #itemDescInput type="text" [value]="item.displayDescription?item.displayDescription:''" (change)="itemDescChanged(item, $event)"/></div>
                                    <div><div style="width: 100px;display: inline-block;">Alt Description</div><input #itemAltDescInput type="text" [value]="item.altDescription?item.altDescription:''" (change)="itemAltDescChanged(item, $event)"/></div>
                                    <div><div style="width: 100px;display: inline-block;">CMS Link</div><input #itemCmsInput type="text" [value]="item.cmsUrl" (change)="itemUrlChanged('cms', item, $event)"/></div>
                                    <ng-container *ngIf="item.cmsUrl">
                                        <div class="link">{{getCMSItemLink(item)}}</div>
                                        <button class="clipboard" [cdkCopyToClipboard]="getCMSItemLink(item)">Copy link to Clipboard</button>
                                    </ng-container>
                                    <div><div style="width: 100px;display: inline-block;">Image Link</div><input #itemImgInput type="text" [value]="item.itemUrl" (change)="itemUrlChanged('img', item, $event)"/></div>
                                    <button (click)="save('item')">Save</button>
                                    <div style="padding: 5px; color: green;display: grid;align-items: center;">{{saveMessage$|async}}</div>
                                    <div style="padding: 5px; color: red;display: grid;align-items: center;">{{saveErrorMessage$|async}}</div>
                                </div>
                            </ng-container>
                        </li>
                    </ng-container>
                </ol>
            </div>
        </div>
    </ng-container>
</li>
