<div id="categorySupplierList" class="category">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <div *ngIf="showNoOffersMessage">There are no offers to display at this time.</div>
        <ng-container *ngFor="let category of sortedCategories">
            <table *ngIf="distributionLookup[category.id]" width="100%" border="0" cellspacing="3" cellpadding="5" class="current_promotion_table">
                <tr class="smsstyle_Header">
                    <td [colSpan]="keys(distributionLookup[category.id]).length">{{categoryLookup[category.id].name}}</td>
                </tr>
                <tr valign="top" align="left" class="smsstyle_Detail">
                    <ng-container *ngFor="let distribution of distributionLookup[category.id]">
                        <td *ngIf="imageUrlExists(distribution, category.id)" style="display: inline-block; text-align: center;" class="smsstyle_Detail_Link" badge="{{calcDeal(distribution)}}" position="top-left" size="large">
                            <a href="{{getItemLink(distribution, category.id)}}" target="_parent">
                                <img src="{{getItemImageUrl(distribution, category.id)}}" alt="{{getItemImageAlt(distribution, category.id)}}" class="smsstyle_img_blacklink" style="width: 230px; height: 230px;">
                                <br><span class="smsstyle_Detail_Description">{{getItemLabel(distribution, category.id)}}</span>
                            </a>
                        </td>
                    </ng-container>
                </tr>
            </table>
        </ng-container>
    </div>
</div>
