import {Injectable} from '@angular/core';
import {AbstractRestService} from './rest-abstract.service';

@Injectable()
export class RestService extends AbstractRestService {

    getRestRoot(): string {
        return this.globals.getRestBase();
    }

}
