import {Component, OnInit} from '@angular/core';
import {Observer} from "rxjs";
import {map, startWith, take} from "rxjs/operators";
import {AdminService} from "../../shared/services/admin.service";
import {UserService} from "../../shared/services/user.service";
import {BaseComponent} from "../base/base.component";
import {Globals} from "../../shared/globals";
import {MatDialog} from "@angular/material/dialog";
import {KeycloakMonitorService} from "../../shared/services/keycloak-monitor.service";
import {MatTabChangeEvent} from "@angular/material/tabs";

export type VersionData = {
    "versionContent": {
        "version": string;
        "buildDate": string;
    }
};

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent extends BaseComponent implements OnInit {

    userId?: string = "";
    lastUpdateText = "";

    selectedTabName =  'Admin';

    constructor(
        protected globals: Globals,
        protected dialog: MatDialog,
        protected keycloakMonitorService: KeycloakMonitorService,
        private service: AdminService,
        protected userService: UserService,) {
        super(globals, dialog, keycloakMonitorService);

    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    initComp(): void {

        this.service.getLastUpdate().pipe(take(1)).subscribe(data => {
            //console.log("getLastUpdate: ", data);
            let structData: VersionData = data as VersionData;
            if (structData.versionContent.buildDate) {
                let utcDate = new Date(structData.versionContent.buildDate);
                //console.log("utcDate:", utcDate);
                this.lastUpdateText = utcDate.toLocaleString();
            }
        });

        this.userService.getUserId().pipe(take(1)).subscribe(user => {
            //console.log("user service result: ", user);
            if (user.userId) {
                this.userId = user.userId;
            }
        });

    }

    onTabChange($event: MatTabChangeEvent) {
        this.selectedTabName = $event.tab.textLabel;
    }
}
