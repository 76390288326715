import {Component, Input, OnInit} from '@angular/core';
import {Distribution} from "../../../shared/model/Distribution";
import {MatDialogRef} from "@angular/material/dialog";
import {Item} from "../../../shared/model/Item";

@Component({
    selector: 'app-distribution-items',
    templateUrl: './distribution-items.component.html',
    styleUrls: ['./distribution-items.component.css']
})
export class DistributionItemsComponent implements OnInit {

    @Input() distribution: Distribution | undefined;

    constructor(private dialogRef: MatDialogRef<DistributionItemsComponent>) {
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

    getItemImageUrl(item: Item): string {
        return item?.imageUrl;
    }

}
