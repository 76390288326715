export enum CustomerGroupType {
    DRACO = "Customer Grouping",
    PRCCRP = "Corp Code"
}
export type CustomerGroupTypeKey = keyof typeof CustomerGroupType;

export function is_customer_group_type_key( key?: string ): key is CustomerGroupTypeKey {
    key = (key||'').trim().toUpperCase();
    return !!key && !!((CustomerGroupType as any)[key] !== undefined);
}

// function example_for_above( ) {
//     const invalid_key = 'invalid';
//     const valid_key = 'DRACO';
//     if ( is_customer_group_type_key(invalid_key) ) {
//         console.log('invalid_key is valid'); // never reached
//         let _  = CustomerGroupType[invalid_key] // scoped type protected
//     }
//     if ( is_customer_group_type_key(valid_key) ) {
//         console.log('valid key is valid');
//         let _ = CustomerGroupType[valid_key]
//     }
//     let _a = CustomerGroupType[invalid_key] // compiler not happy
//     let _b = CustomerGroupType[valid_key] // compiler happy
// }

// A method that seemed like a good idea
// export namespace CustomerGroupType {
//     export function values() {
//         return Object.keys(CustomerGroupType).filter(
//             (type) => isNaN(<any>type) && type !== 'values'
//         );
//     }
// }
