import {ItemUrl} from "./ItemUrl";
import {CachedDistribution} from "./CachedDistribution";
import {Entry} from "./entry";
import {IndexSigned} from "./index-signed";

export type CachedDistributionEntry = Entry<string, CachedDistribution[]>;
export interface CachedDistributionLookup extends IndexSigned<CachedDistribution[]>{}
export function create_cached_distribution_entries(map: CachedDistributionLookup): CachedDistributionEntry[] {
    return [...Object.entries(map)];
}
