<ng-template #DistributionContent let-itemNumber="itemNumber" let-endDate="endDate">
    <div>Item {{itemNumber}}, which is available for ordering until: {{endDate}}</div>
</ng-template>
<ng-template #BundleDistributionContent let-distribution="distribution">
    <div>Bundle {{distribution.description}}, which is available for ordering until: {{distribution?.lastAvailableDate}}</div>
</ng-template>
<ng-container *ngIf="distributions.length > 0">
    <table cellspacing="1" cellpadding="1">
        <tr>
            <th class="header-column-my-truck"><div>MY TRUCK</div></th>
            <th class="header-column-description"><div>DESCRIPTION</div></th>
            <th class="header-column-uin"><div>UIN</div></th>
            <th class="header-column-upc"><div>UPC</div></th>
            <th class="header-column-pack" style="padding-top: 4px;padding-bottom: 5px;"><div>PACK <br> SIZE</div></th>
            <th class="header-column-cost"><div>COST $</div></th>
            <th class="header-column-allow"><div>ALLOW $</div></th>
            <th class="header-column-unit-cost"><div>UNIT COST $</div></th>
            <th class="header-column-dates" style="padding-top: 4px;padding-bottom: 5px;"><div>SHIP <br> DATES</div></th>
            <th class="header-column-gmp"><div>GMP</div></th>
        </tr>
        <ng-container *ngFor="let distribution of distributions">
            <tr class="row-repeat" [ngbTooltip]="DistributionContent" placement="bottom" tooltipClass="distribute-tooltip" #t1="ngbTooltip" (mouseenter)="showItemToolTip(t1, distribution.itemSet![0].id.toString(), distribution.lastAvailableDate!)">
                <td class="repeat-column-my-truck">
                    <button style="margin-right: 10px;" (click)="addToTruckClicked(distribution)">{{getAddRemoveActionText(distribution)}}</button>
                    <button alt="Allocate" (click)="allocateClicked(distribution)">Allocate</button>
                </td>
                <td class="repeat-column-description">{{distribution.itemSet![0].description}}</td>
                <td class="repeat-column-uin">{{distribution.itemSet![0].uin}}</td>
                <td class="repeat-column-upc">{{distribution.itemSet![0].upc}}</td>
                <td class="repeat-column-pack">
                    {{distribution.itemSet![0].pack}}
                    <br/>
                    {{distribution.itemSet![0].size}}
                </td>
                <td class="repeat-column-cost">{{distribution.itemSet![0].billCost}}</td>
                <td class="repeat-column-allow">{{distribution.itemSet![0].allowance}}</td>
                <td class="repeat-column-unit-cost">{{distribution.itemSet![0].unitCost}}</td>
                <td class="repeat-column-dates">
                    {{distribution?.firstShipDate}}
                    <br/>
                    {{distribution?.lastShipDate}}
                </td>
                <td class="repeat-column-gmp">{{distribution.itemSet![0].gmp}}</td>
            </tr>
        </ng-container>
    </table>
</ng-container>
<ng-container *ngIf="bundleDistributions.length > 0">
    <div style="margin-left: 10px; margin-top: 20px;">Bundle Items:</div>
    <table cellspacing="1" cellpadding="1">
        <tr>
            <th class="header-column-my-truck"><div>MY TRUCK</div></th>
            <th class="header-column-description"><div>DESCRIPTION</div></th>
            <th class="header-column-uin"><div>UIN</div></th>
            <th class="header-column-upc"><div>UPC</div></th>
            <th class="header-column-pack" style="padding-top: 4px;padding-bottom: 5px;"><div>PACK <br> SIZE</div></th>
            <th class="header-column-cost"><div>COST $</div></th>
            <th class="header-column-allow"><div>ALLOW $</div></th>
            <th class="header-column-unit-cost"><div>UNIT COST $</div></th>
            <th class="header-column-dates" style="padding-top: 4px;padding-bottom: 5px;"><div>SHIP <br> DATES</div></th>
            <th class="header-column-gmp"><div>GMP</div></th>
        </tr>
        <ng-container *ngFor="let distribution of bundleDistributions">
            <tr *ngIf="!distribution.showDetail" class="row-repeat" [ngbTooltip]="BundleDistributionContent" placement="bottom" tooltipClass="distribute-tooltip" #t1="ngbTooltip" (mouseenter)="showToolTip(t1, distribution)">
                <td class="repeat-column-my-truck">
                    <button class="bundle-detail-action" (click)="toggleBundleDetails(distribution)">{{distribution.showDetail ? 'Hide Detail' : 'Show Detail'}}</button><br/>
                    <button style="margin-right: 10px;" (click)="addToTruckClicked(distribution)">{{getAddRemoveActionText(distribution)}}</button>
                    <button alt="Allocate" (click)="allocateClicked(distribution)">Allocate</button>
                </td>
                <td class="repeat-column-description">{{distribution?.description}}</td>
                <td class="repeat-column-uin">{{distribution.itemSet![0].uin}}</td>
                <td class="repeat-column-upc">{{distribution.itemSet![0].upc}}</td>
                <td class="repeat-column-pack">
                    {{distribution.itemSet![0].pack}}
                    <br/>
                    {{distribution.itemSet![0].size}}
                </td>
                <td class="repeat-column-cost">{{distribution.itemSet![0].billCost}}</td>
                <td class="repeat-column-allow">{{distribution.itemSet![0].allowance}}</td>
                <td class="repeat-column-unit-cost">{{distribution.itemSet![0].unitCost}}</td>
                <td class="repeat-column-dates">
                    {{distribution?.firstShipDate}}
                    <br/>
                    {{distribution?.lastShipDate}}
                </td>
                <td class="repeat-column-gmp">{{distribution.itemSet![0].gmp}}</td>
            </tr>
            <tr *ngIf="distribution.showDetail" class="row-repeat" [ngbTooltip]="BundleDistributionContent" placement="bottom" tooltipClass="distribute-tooltip" #t1="ngbTooltip" (mouseenter)="showToolTip(t1, distribution)">
                <td class="repeat-column-my-truck">
                    <button class="bundle-detail-action" (click)="toggleBundleDetails(distribution)">{{distribution.showDetail ? 'Hide Detail' : 'Show Detail'}}</button><br/>
                    <div>{{distribution.description}}</div>
                    <button style="margin-right: 10px;" (click)="addToTruckClicked(distribution)">{{getAddRemoveActionText(distribution)}}</button>
                    <button alt="Allocate" (click)="allocateClicked(distribution)">Allocate</button>
                </td>
                <td colspan="9"></td>
            </tr>
            <ng-container *ngIf="distribution.showDetail">
                <ng-container *ngFor="let item of distribution.itemSet">
                    <tr class="row-repeat" [ngbTooltip]="DistributionContent" placement="bottom" tooltipClass="distribute-tooltip" #t1="ngbTooltip" (mouseenter)="showItemToolTip(t1, item.id.toString(), distribution.lastAvailableDate!)">
                        <td></td>
                        <td class="repeat-column-description">{{item?.description}}</td>
                        <td class="repeat-column-uin">{{item.uin}}</td>
                        <td class="repeat-column-upc">{{item.upc}}</td>
                        <td class="repeat-column-pack">
                            {{item.pack}}
                            <br/>
                            {{item.size}}
                        </td>
                        <td class="repeat-column-cost">{{item.billCost}}</td>
                        <td class="repeat-column-allow">{{item.allowance}}</td>
                        <td class="repeat-column-unit-cost">{{item.unitCost}}</td>
                        <td class="repeat-column-dates">
                            {{distribution?.firstShipDate}}
                            <br/>
                            {{distribution?.lastShipDate}}
                        </td>
                        <td class="repeat-column-gmp">{{item.gmp}}</td>
                    </tr>
                </ng-container>
            </ng-container>
        </ng-container>
    </table>
</ng-container>
<br/><br/><br/><br/>
