import {
    AfterViewInit,
    Component,
    Input,
    OnInit, Output,
    ViewChild,
    ViewEncapsulation,
    EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges
} from '@angular/core';
import {Distribution} from "../../../shared/model/Distribution";
import {Item} from "../../../shared/model/Item";
import {map, startWith, take} from "rxjs/operators";
import {DistributionService} from "../../../shared/services/distribution.service";
import {AllocateService} from "../../../shared/services/allocate.service";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {MatDialog} from "@angular/material/dialog";
import {ItemsInTruckSelectComponent} from "../items-in-truck-select/items-in-truck-select.component";
import {DistributionItemsComponent} from "../distribution-items/distribution-items.component";
import {BehaviorSubject, EMPTY, Observable, Observer} from "rxjs";
import {CustomerOrderQuantity} from "../../../shared/model/CustomerOrderQuantity";
import {MatTableDataSource} from "@angular/material/table";
import {CustomerFilter} from "../../../shared/model/CustomerFilter";

@Component({
    selector: 'app-item-detail',
    templateUrl: './item-detail.component.html',
    styleUrls: ['./item-detail.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailComponent implements OnInit, AfterViewInit, OnChanges {

    loading$: Observable<boolean> = EMPTY;

    @ViewChild('tt1', {static: false}) tt1: NgbTooltip | undefined;
    @ViewChild('tt2', {static: false}) tt2: NgbTooltip | undefined;
    @ViewChild('tt3', {static: false}) tt3: NgbTooltip | undefined;

    distributionKey?: string;
    @Output() distributionChanged = new EventEmitter<Distribution>();

    @Input() distribution?: Distribution;
    distributionItem?: Item;

    itemsInTruckText: string = "1 Item/Bundle in the Truck...";

    distributionsInTruck?: Distribution[] = [];

    distributionImageUrl?: string = "";

    private customerFilterSubject: BehaviorSubject<CustomerFilter> = new BehaviorSubject<CustomerFilter>({});
    public customerFilter$: Observable<CustomerFilter> = this.customerFilterSubject.asObservable();

    constructor(
        private distributionService: DistributionService,
        private allocateService: AllocateService,
        public dialog: MatDialog) {}

    ngOnInit(): void {
        this.loadDistribution();
        this.loadOtherDistributionsInTruck();
    }

    ngAfterViewInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        //console.log("ItemDetailComponent ngOnChanges changes: ", changes);
        if (changes.distribution) {
            this.loadDistribution();
        }
    }

    loadDistribution() {
        if (this.distribution && this.distribution.key && (this.distribution.key != this.distributionKey)) {
            this.distributionKey = this.distribution.key;
            const distObserver: Observer<Distribution[]> = {
                next: data => {
                    //console.log("distribution data:", data);
                    this.distribution = data[0];
                    if (this.distribution && this.distribution.itemSet![0]) {
                        this.distributionItem = this.distribution.itemSet![0];
                        this.distributionChanged.emit(this.distribution);
                        this.distributionImageUrl = this.distribution.imageUrl ? this.distribution.imageUrl : this.distributionItem?.imageUrl;
                        //console.log("this.distributionImageUrl: ", this.distributionImageUrl);
                    }
                },
                error: _ => {
                },
                complete: () => {
                }
            };
            //console.log("fetching distributionKey:", this.distribution.key);
            const distServiceCall = this.distributionService.getDistribution(this.distribution.key).pipe(take(1));
            this.loading$ = distServiceCall.pipe(map(_=>false), startWith(true));
            distServiceCall.subscribe(distObserver);
        }

    }

    loadOtherDistributionsInTruck() {
        const truckObserver: Observer<Distribution[]> = {
            next: data => {
                //console.log("distributions in truck data:", data);
                this.distributionsInTruck = data;
                if (this.distributionsInTruck) {
                    if (this.distributionsInTruck.length > 1) {
                        this.itemsInTruckText = this.distributionsInTruck.length + " Items/Bundles in the Truck...";
                    } else {
                        this.itemsInTruckText = "1 Item/Bundle in the Truck...";
                    }
                }
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const truckServiceCall = this.allocateService.getDistributionsInTruck().pipe(take(1));
        this.loading$ = truckServiceCall.pipe(map(_=>false), startWith(true));
        truckServiceCall.subscribe(truckObserver);
    }

    deleteFromTruck() {
        let itemNum = 0;
        if (!this.distribution?.bundle && this.distribution?.itemSet) {
            itemNum = this.distribution.itemSet[0].id;
        }
        let defaultAllocateCustomerFilter: CustomerFilter = {
            distributionId:this.distribution?.id,
            itemNumber:itemNum,
            mcLaneDiv:"",
            divPart:0,
            customerGroupId:"",
            custDivPart:0,
            custId:"ALLOCATE",
            shipDate:"2999-01-01",
        }
        const callObserver: Observer<void> = {
            next: data => {
            },
            error: _ => {
            },
            complete: () => {
                this.loadDistribution();
            }
        };
        const serviceCall = this.allocateService.addRemoveTruck(defaultAllocateCustomerFilter, 'delete').pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }

    showItemsInTruck() {
        let dialogRef = this.dialog.open(ItemsInTruckSelectComponent, {
            width: '600px',
            height: '200px',
        });
        let dialogComponent = dialogRef.componentInstance;
        dialogComponent.distributionsInTruck = this.distributionsInTruck;
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                //console.log("Dialog output:", data);
                let dist = data as Distribution;
                this.distributionKey = dist.key;
                this.distributionChanged.emit(dist);
                this.loadDistribution();
            }
        });
    }

    showDistributionItems() {
        let dialogRef = this.dialog.open(DistributionItemsComponent, {
            width: '600px',
            height: '200px',
        });
        let dialogComponent = dialogRef.componentInstance;
        dialogComponent.distribution = this.distribution;
    }

    getDescription(): string {
        if (this.distribution) {
            if (this.distribution.bundle && this.distribution.description) {
                return this.distribution.description;
            } else {
                if (this.distribution.itemSet) {
                    return this.distribution.itemSet[0].description;
                }
            }
        }
        return '';
    }
}
