<div style="padding: 20px;">
    <mat-tab-group (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Admin">
            <ng-template matTabContent>
                <br/>The application was last updated on
                <span class="yellow">{{lastUpdateText}}</span>
                <app-url-maint [selectedParentTabName]="selectedTabName"></app-url-maint>
            </ng-template>
        </mat-tab>
        <mat-tab label="Data">
            <ng-template matTabContent>
                <app-data></app-data>
            </ng-template>
        </mat-tab>
        <mat-tab label="Actions">
            <ng-template matTabContent>
                <app-actions></app-actions>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
