import { KeycloakService } from 'keycloak-angular';
import { from }            from 'rxjs';

import { mergeMap, take }        from 'rxjs/operators';
import { KeycloakConfigService } from "../services/keycloak-config.service";
import {KeycloakMonitorService} from "../services/keycloak-monitor.service";


export function initializer(keycloak: KeycloakService,
                            keycloakConfigService: KeycloakConfigService,
                            keycloakMonitorService: KeycloakMonitorService): () => Promise<any> {
    //console.log('Keycloak initializing...');
    return () => keycloakConfigService.getAuthConfig().pipe(
        take(1),
        mergeMap(config => from (
            keycloak.init({
                config,
                initOptions: {
                    onLoad: 'login-required',
                    checkLoginIframe: false
                },
                enableBearerInterceptor: true
            }).then((result) => {
                //console.log('Keycloak initialized with result of:', result);
                //console.log('Keycloak: ', keycloak);
                if (result) {
                    keycloak.isLoggedIn().then((answer) => {
                        //console.log('Logged In:', answer);
                        if (answer) {
                            //console.log('Notify subscribers we are logged in.....');
                            keycloakMonitorService.setLoggedIn(keycloak);
                        }
                    });
                }
            })
        )),
    ).toPromise();
}
