<ng-template #tt4Text>
    Customers can be displayed by Corp Code or by the customer groupings assigned for your User Id. <p>
    Customer Groupings are used to select a list of stores that are grouped best for ordering (i.e. by geographic region or store sales volume.) <p>
    Corp code selection will show all stores for an entire corporation group.
</ng-template>
<ng-template #tt5Text>
    Use this to choose a list of stores for the selected customer group type (Corp Code or Customer Grouping).
</ng-template>
<ng-template #tt6Text>
    Selecting a division will show all stores serviced for that division.  Selecting 'ALL' will show all stores for the selected store list.
</ng-template>
<ng-template #tt7Text>
    Worklists can provide further filtering for the current store grouping.  Worklists can be created by selecting the 'New Worklist' button
</ng-template>
<ng-template #tt8Text>
    Click this to create a new worklist for the current store list.  One or more stores must be selected from the store list
</ng-template>
<div class="customer-select">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <div class="top-row" style="position: relative;">
            <div class="left-column"
                 (click)="showCustomerSelect()"
                 style="text-decoration: underline; "
                 [ngbTooltip]="tt4Text"
                 tooltipClass="t4-tooltip"
                 placement="top"
                 triggers="manual"
                 #tt4="ngbTooltip"
                 appTooltip="helpTooltipController"
                 [tooltip]="tt4">
                <div>{{groupingText}}</div>
            </div>
            <app-customer-group-select *ngIf="groupSelectVisible$ | async" (change)="onCustomerGroupTypeSelect($event)"></app-customer-group-select>
            <div class="right-column">
                <mat-select #customerGroupSelect style="width: 150px;border: 1px solid darkgray;"
                    [(value)]="selectedCustomerGroupId"
                    (valueChange)="customerGroupSelectChanged()"
                    [ngbTooltip]="tt5Text"
                    tooltipClass="t5-tooltip"
                    placement="top"
                    triggers="manual"
                    #tt5="ngbTooltip"
                    appTooltip="helpTooltipController"
                    [tooltip]="tt5">
                    <mat-option *ngFor="let customerGroup of customerGroups" [value]="customerGroup.value">
                        {{customerGroup.display}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="center-row">
            <div class="left-column">Division</div>
            <div class="right-column">
                <mat-select style="width: 150px;border: 1px solid darkgray;"
                    [(value)]="selectedDivision"
                    (valueChange)="divisionSelectChanged()"
                    [ngbTooltip]="tt6Text"
                    tooltipClass="t6-tooltip"
                    placement="top"
                    triggers="manual"
                    #tt6="ngbTooltip"
                    appTooltip="helpTooltipController"
                    [tooltip]="tt6">
                    <mat-option *ngFor="let division of divisions" [value]="division.mcLaneDiv">
                        {{division.userDivName}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <!-- Worklist was not functional in production it will be disabled until requested -->
        <div class="bottom-row" style="visibility: hidden;">
            <div class="left-column">Worklist</div>
            <div class="right-column">
                <mat-select style="width: 150px;border: 1px solid darkgray;margin-right: 5px;"
                    [(value)]="selectedWorklist"
                    (valueChange)="worklistSelectChanged()"
                    [ngbTooltip]="tt7Text"
                    tooltipClass="t7-tooltip"
                    placement="top"
                    triggers="manual"
                    #tt7="ngbTooltip"
                    appTooltip="helpTooltipController"
                    [tooltip]="tt7">
                    <mat-option *ngFor="let workList of workLists" [value]="workList.value">
                        {{workList.display}}
                    </mat-option>
                </mat-select>
                <div class="worklist-action"
                     (click)="worklistSelect()"
                     [ngbTooltip]="tt8Text"
                     tooltipClass="t8-tooltip"
                     placement="top"
                     triggers="manual"
                     #tt8="ngbTooltip"
                     appTooltip="helpTooltipController"
                     [tooltip]="tt8"></div>
            </div>
        </div>
    </div>
</div>
