import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {Distribution} from "../../../shared/model/Distribution";

@Component({
    selector: 'app-items-in-truck-select',
    templateUrl: './items-in-truck-select.component.html',
    styleUrls: ['./items-in-truck-select.component.css']
})
export class ItemsInTruckSelectComponent implements OnInit {

    @Input() distributionsInTruck?: Distribution[] = [];

    constructor(private dialogRef: MatDialogRef<ItemsInTruckSelectComponent>) {
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

    getDistributionImageUrl(distribution: Distribution): string {
        return distribution?.imageUrl!;
    }

    getDistributionItemAttr(distribution: Distribution, attribute: string): string {
        let rtnVal = "";
        switch (attribute) {
            case 'id': {
                if (distribution.bundle) {
                    rtnVal = "";
                } else {
                    rtnVal = distribution.itemSet![0].id.toString();
                }
                break;
            }
            case 'description': {
                if (distribution.bundle) {
                    rtnVal = "Bundle Distribution";
                } else {
                    rtnVal = distribution.itemSet![0].description.toString();
                }
                break;
            }
        }
        return rtnVal;
    }

    selectDistribution(distribution: Distribution) {
        this.dialogRef.close(distribution);
    }
}
