import {Component, OnInit} from '@angular/core';
import {EMPTY, Observable, Observer} from "rxjs";
import {AdminService} from "../../../../shared/services/admin.service";
import {Category} from "../../../../shared/model/Category";
import {map, startWith, take} from "rxjs/operators";
import {ItemUrl} from "../../../../shared/model/ItemUrl";
import {ItemUrlRecord} from "../../../../shared/model/ItemUrlRecord";

interface IOptions {
    [propName: string]: string;
}

@Component({
    selector: 'app-item-urls',
    templateUrl: './item-urls.component.html',
    styleUrls: ['./item-urls.component.css']
})
export class ItemUrlsComponent implements OnInit {

    loading$: Observable<boolean> = EMPTY;

    itemUrls: [string,ItemUrl][] = [];

    public keys: (o:any) => string[] = Object.keys;

    constructor(private service: AdminService) {}

    ngOnInit(): void {
        const callObserver: Observer<ItemUrlRecord> = {
            next: data => {
                //console.log("data:", data);
                this.itemUrls = [...Object.entries(data)];
                //console.log("itemUrls:", this.itemUrls);
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const serviceCall = this.service.getItemUrls().pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }

    getItemUrlText([key,itemUrl]: [string,ItemUrl]): string {
        if (this.itemUrls) {
            //console.log("this.itemUrls: ", this.itemUrls);
            //console.log(`${key} => ${itemUrl.itemId}`);
            if (itemUrl) {
                return `${key} - ${itemUrl.urlType} - ${itemUrl.url}`
            }
        }
        return ``;
    }

}
