import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Distribution} from "../../shared/model/Distribution";
import {map, startWith, take} from "rxjs/operators";
import {DistributionService} from "../../shared/services/distribution.service";
import {AllocateComponent} from "../allocate/allocate.component";
import {MatDialog} from "@angular/material/dialog";
import {AllocateService} from "../../shared/services/allocate.service";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {EMPTY, Observable, Observer} from "rxjs";
import {CustomerFilter} from "../../shared/model/CustomerFilter";
import {DialogService} from "../../shared/services/dialog.service";
import {BaseComponent} from "../base/base.component";
import {KeycloakMonitorService} from "../../shared/services/keycloak-monitor.service";
import {Globals} from "../../shared/globals";

@Component({
    selector: 'app-distribution',
    templateUrl: './distribution.component.html',
    encapsulation: ViewEncapsulation.None,
    //styleUrls: [''],
    styles: [`
        @import url('../../../../node_modules/bootstrap/dist/css/bootstrap.css');
        body {
            color: #404040;
            font-family: 'Montserrat';
            font-size: 13px;
            line-height: 1.5;
        }

        button {
            border: 1px solid;
            border-color: #ccc #ccc #bbb;
            border-radius: 3px;
            background: #e6e6e6;
            color: rgba(0, 0, 0, 0.8);
            font-size: 12px;
            font-size: 0.75rem;
            line-height: 1;
            padding: 0.6em 1em 0.4em;
            margin-top: 10px;
        }

        table {
            font-family: 'Montserrat';
            border: none;
            width: 75%;
            margin-right: 10px;
            margin-left:  10px;
            margin-top: 10px;
        }

        th {
            font-weight: normal;
            font-size: 13px;
            padding-left: 10px;
            padding-right: 10px;
            border-color: rgb(188, 190, 192);
            border-collapse: separate;
            border-style: solid;
            border-width: 1px;
            background-color: rgb(230, 231, 232);
            color: #404040;
            white-space: nowrap;
            text-align: center;
        }

        td {
            font-size: 13px;
            text-align: center;
        }

        .header-column-my-truck{
            min-width: 165px;
        }
        .header-column-description{
            min-width: 225px;
            text-align: left;
            white-space: nowrap;
        }
        .header-column-uin{
            min-width: 35px;
        }
        .header-column-upc{
            min-width: 65px;
        }
        .header-column-pack{
            min-width: 55px;
        }
        .header-column-cost{
            min-width: 60px;
        }
        .header-column-allow{
            min-width: 80px;
        }
        .header-column-unit-cost{
            min-width: 105px;
        }
        .header-column-dates{
            min-width: 65px;
        }
        .header-column-gmp{
            min-width: 40px;
        }

        .row-repeat {
            min-height: 50px;
        }

        .repeat-column-my-truck{
            min-width: 165px;
        }
        .repeat-column-description{
            min-width: 225px;
            text-align: left;
            white-space: nowrap;
        }
        .repeat-column-uin{
            min-width: 35px;
        }
        .repeat-column-upc{
            min-width: 65px;
        }
        .repeat-column-pack{
            min-width: 55px;
        }
        .repeat-column-cost{
            min-width: 60px;
        }
        .repeat-column-allow{
            min-width: 80px;
        }
        .repeat-column-unit-cost{
            min-width: 105px;
        }
        .repeat-column-dates{
            min-width: 65px;
        }
        .repeat-column-gmp{
            min-width: 40px;
        }

        @media (max-width: 768px) {
            table {
                word-break: break-word;
            }

            th {
                white-space: normal;
            }
        }

        .bundle-detail-action {
            background: none;
            border: none;
            padding: 0;
            text-decoration: underline;
            cursor: pointer;
        }

        tr:nth-child(odd) {
            background-color: rgb(230, 231, 232);
        }
    `]
})
export class DistributionComponent extends BaseComponent implements OnInit, AfterViewInit {

    loading$: Observable<boolean> = EMPTY;

    distributionKey?: string;
    category?: string;
    supplier?: string;
    distributions: Distribution[] = [];
    bundleDistributions: Distribution[] = [];
    inTruckKeys: string[] = [];

    constructor(
        protected globals: Globals,
        private service: DistributionService,
        private allocateService: AllocateService,
        private dialogService: DialogService,
        protected dialog: MatDialog,
        protected keycloakMonitorService: KeycloakMonitorService
    ) {
        super(globals, dialog, keycloakMonitorService);
        var urlParams = new URLSearchParams(window.location.search);
        //console.log("urlParams:", urlParams);
        if (urlParams.has('key')) {
            this.distributionKey = ""+urlParams.get('key');
        }
        //console.log("1) distributionKey=", this.distributionKey);
        if (urlParams.has('category')) {
            this.category = ""+urlParams.get('category');
        }
        //console.log("category=", this.category);
        if (urlParams.has('supplier')) {
            this.supplier = ""+urlParams.get('supplier');
        }
        //console.log("supplier=", this.supplier);
    }

    ngAfterViewInit(): void {

    }

    ngOnInit(): void {
        //console.log("ngOnInit");
        super.ngOnInit();
    }

    initComp() {
        //console.log("Initializing Component");
        this.refresh();
    }

    refresh() {
        this.getDistributions();
        this.getDistributionsInTruck();
    }

    getDistributions() {
        this.distributions = [];
        this.bundleDistributions = [];
        //console.log(`DistributionComponent getDistributions keys:${this.distributionKey} ${this.category} ${this.supplier}`);
        //console.log("2) distributionKey=", this.distributionKey);
        if (this.distributionKey && this.distributionKey.length > 0) {
            //console.log(`getting data for distributionKey:${this.distributionKey} ${this.category} ${this.supplier}`);
            this.service.getDistributions(this.distributionKey, this.category, this.supplier)
                .pipe(
                    take(1),
                    map( (x: Distribution[] | Distribution) : Distribution[] => x instanceof Array ? [...x] : x ? [x] : [] )
                )
                .subscribe((data: Distribution[] = []) => {
                    //console.log("data={}", data);
                    data.forEach( d => {
                        if (d.bundle == true) {
                            this.bundleDistributions.push(d);
                        } else {
                            this.distributions.push(d);
                        }
                    });
                });
        } else {
            this.dialogService.alert({
                title: "Development Error",
                message: "Missing distribution lookup (.../distribution?key=XXXXX or .../distribution?category=XXXXX&supplier=XXXX)",
                confirmText: "Ok"
            });
        }
    }

    getDistributionsInTruck() {
        this.inTruckKeys = [];
        const truckObserver: Observer<Distribution[]> = {
            next: data => {
                //console.log("distributions in truck data:", data);
                data.forEach( d => this.inTruckKeys.push(d.key!));
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const truckServiceCall = this.allocateService.getDistributionsInTruck().pipe(take(1));
        this.loading$ = truckServiceCall.pipe(map(_=>false), startWith(true));
        truckServiceCall.subscribe(truckObserver);
    }

    addToTruckClicked(distribution : Distribution) {
        let remove = this.inTruckKeys.includes(distribution.key!);
        let itemNum = 0;
        if (!distribution?.bundle && distribution?.itemSet) {
            itemNum = distribution.itemSet[0].id;
        }
        let defaultAllocateCustomerFilter: CustomerFilter = {
            distributionId:distribution?.id,
            itemNumber:itemNum,
            mcLaneDiv:"",
            divPart:0,
            customerGroupId:"",
            custDivPart:0,
            custId:"ALLOCATE",
            shipDate:"2999-01-01",
        }
        const callObserver: Observer<void> = {
            next: data => {
                this.getDistributions();
                this.getDistributionsInTruck();
                window.location.reload();
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const serviceCall = this.allocateService.addRemoveTruck(defaultAllocateCustomerFilter, remove?'delete':'add').pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }

    allocateClicked(distribution: Distribution) {
        let dialogRef = this.dialog.open(AllocateComponent, {
            height: '700px',
            width: '900px',
        });
        let allocate = dialogRef.componentInstance;
        allocate.dialogMode = true;
        allocate.updateDistribution(distribution);
        dialogRef.afterClosed().subscribe((result) => {
            //console.log("result:", result);
            this.refresh();
        });
    }

    showToolTip(tooltip: NgbTooltip, distribution: Distribution) {
        //console.log("tooltip", tooltip);
        //console.log("distribution", distribution);
        tooltip.open({distribution});
    }

    showItemToolTip(tooltip: NgbTooltip, itemNumber: string, endDate: string) {
        //console.log("tooltip", tooltip);
        //console.log("itemNumber", itemNumber);
        //console.log("endDate", endDate);
        tooltip.open({itemNumber, endDate});
    }

    toggleBundleDetails(distribution: Distribution) {
        distribution.showDetail = !distribution.showDetail;
    }

    getAddRemoveActionText(distribution: Distribution) {
        let text = "Add";
        if (this.inTruckKeys.includes(distribution.key!)) {
            text = "Remove";
        }
        return text;
    }
}
