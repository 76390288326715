import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {CustomerGroupType, CustomerGroupTypeKey} from "../model/CustomerGroupType";
import {CustomerGroup} from "../model/CustomerGroup";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Distribution} from "../model/Distribution";
import {LoginType} from "../model/LoginType";
import {RestService} from "./rest.service";
import {Globals} from "../globals";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(protected globals: Globals,
              protected http: HttpClient,
              private restService: RestService) { }

    getCustomerGroups(customerGroupType: CustomerGroupTypeKey, loginType: LoginType): Observable<CustomerGroup[]> {
        let getPath = this.globals.getRestBase() + "customer/";
        switch (customerGroupType) {
            case 'PRCCRP': {
                getPath = getPath + "corps";
                break;
            }
            case 'DRACO': {
                getPath = getPath + "groups/" + LoginType[loginType];
                break;
            }
            default: {
                throw `Invalid CustomerGroupTypeKey or Logic Not Implemented for ${customerGroupType}`
            }
        }
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<CustomerGroup[]>(getPath, options);
    }
}
