import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styles: [`
        .t1-tooltip .tooltip-inner {
            background-color: darkgreen;
            font-size: 125%;
        }
        .t1-tooltip .arrow::before {
            border-top-color: darkgreen;
        }
    `]
})
export class TooltipComponent implements OnInit {

    @ViewChild('t1', {static: false}) mytooltip: NgbTooltip | undefined;

    constructor() {
        //console.log("TooltipComponent mytooltip={}", this.mytooltip);
    }

    ngOnInit(): void {
        //console.log("TooltipComponent mytooltip={}", this.mytooltip);
    }

    showTooltip(){
        this.mytooltip?.open();
    }

    hideTooltip(){
        this.mytooltip?.close();
    }
}
