import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {TooltipDirective} from "./tooltip.directive";
import {takeUntil} from "rxjs/operators";

@Directive({
    selector: '[appTooltipController]'
})
export class TooltipControllerDirective implements OnInit, OnDestroy {

    @Input("appTooltipController") public id!: string;

    @Input() public open!: Observable<number>;
    @Input() public next!: Observable<void>;
    @Input() public previous!: Observable<void>;
    @Input() public close!: Observable<void>;

    private destroy!: Subject<void>;

    private idx = 0;
    private tooltips: TooltipDirective[] = [];
    private static references = new Map<string, TooltipControllerDirective>();

    private static initMe = new Map<string, TooltipDirective>();

    constructor() {}

    ngOnInit(): void {
        this.destroy = new Subject<void>();
        this.open.pipe(takeUntil(this.destroy)).subscribe( idx => this.openTooltip(idx));
        this.next.pipe(takeUntil(this.destroy)).subscribe(() => this.nextTooltip());
        this.previous.pipe(takeUntil(this.destroy)).subscribe(() => this.previousTooltip());
        this.close.pipe(takeUntil(this.destroy)).subscribe(() => this.closeTooltip());
        TooltipControllerDirective.references.set(this.id, this);
        const toInit = TooltipControllerDirective.initMe.get(this.id);
        if (toInit !== undefined) {
            this.tooltips.push(toInit);
        }
    }

    ngOnDestroy(): void {
        TooltipControllerDirective.references.delete(this.id);
        this.destroy.next();
        this.destroy.complete();
    }

    public static registerToolTip(id: string, tooltip: TooltipDirective) {
        const controller = this.references.get(id);
        if (controller !== undefined) {
            controller.tooltips.push(tooltip);
        } else {
            this.initMe.set(id, tooltip);
        }
    }

    private openTooltip(at: number = this.idx) {
        this.idx = at;
        if(!this.is_idx_valid) return;
        this.tooltips[at].open();
    }

    private nextTooltip() {
        const lastIdx = this.idx;
        this.idx += 1;
        if (this.idx > this.tooltips.length - 1) {
            this.idx = 0;
        }
        if (!this.is_idx_valid) return;
        if (lastIdx !== this.idx) {
            this.tooltips[lastIdx].close();
        }
        this.tooltips[this.idx].open();
    }

    private previousTooltip() {
        const lastIdx = this.idx;
        this.idx -= 1;
        if (this.idx < 0) {
            this.idx = this.tooltips.length - 1;
        }
        if (!this.is_idx_valid) return;
        if (lastIdx !== this.idx) {
            this.tooltips[lastIdx].close();
        }
        this.tooltips[this.idx].open();
    }

    private get is_idx_valid() {
        return this.idx >= 0 && this.idx < this.tooltips.length;
    }

    private closeTooltip() {
        this.tooltips[this.idx].close();
    }

}
