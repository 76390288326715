<div style="padding: 20px;">
    <p>
    Number of tradeshow groupings loaded={{tradeshowGroupings.length}}
    </p>
    <code>
        <ol>
            <li *ngFor="let tradeshowGrouping of tradeshowGroupings">
                <div><!--sb.append(String.format("%1$3s", tradeshowGrouping.getKey()-->{{tradeshowGrouping.key}} - {{tradeshowGrouping.name}}</div>
                <ol>
                    <li *ngFor="let tradeshow of tradeshowGrouping.tradeshowSet">
                        <div><!--String.format("%1$3s", tradeshow.getId())-->{{tradeshow.id}} - {{tradeshow.name}} - {{tradeshow.startDate}} - {{tradeshow.endDate}}</div>
                    </li>
                </ol>
            </li>
        </ol>
   </code>
</div>
