<div class="inactive-customers">
    <div class="top-row">
        <span class="close-text" (click)="close()">Close</span>
    </div>
    <div class="inactive-customer-row">
        <table cellspacing="0" cellpadding="0">
            <thead>
            <tr>
                <th>Div</th>
                <th>Cust #</th>
                <th>Store #</th>
                <th>Name</th>
                <th>Address</th>
                <th>City</th>
                <th>ST</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let coq of inactiveCustomers">
                <td>{{coq.mcLaneDiv}}</td>
                <td>{{coq.customerNumber}}</td>
                <td>{{coq.storeNumber}}</td>
                <td>{{coq.storeName}}</td>
                <td>{{coq.address}}</td>
                <td>{{coq.city}}</td>
                <td>{{coq.state}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
