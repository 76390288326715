<div style="padding: 20px;">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <p>
            Number of Suppliers loaded={{suppliers.length}}
        </p>
        <code>
            <ol>
                <li *ngFor="let supplier of suppliers">
                    <div>{{supplier.id}} - {{supplier.name}}</div>
                </li>
            </ol>
        </code>
    </div>
</div>
