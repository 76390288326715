import {Component, OnInit, ViewChild} from '@angular/core';
import {EMPTY, Observable, Observer} from "rxjs";
import {AdminService} from "../../../shared/services/admin.service";
import {Category} from "../../../shared/model/Category";
import {map, startWith, take} from "rxjs/operators";
import {RefreshStatus} from "../../../shared/model/RefreshStatus";

@Component({
    selector: 'app-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {

    loading$: Observable<boolean> = EMPTY;

    @ViewChild('refreshButton') refreshAction!: HTMLButtonElement;
    refreshStatusText = "";

    constructor(private service: AdminService) {
    }

    ngOnInit(): void {
    }

    refreshCache() {
        this.refreshStatusText = "";
        const callObserver: Observer<RefreshStatus> = {
            next: data => {
                //console.log("refreshCache data:", data);
                if (data.refreshStatus) {
                    this.refreshStatusText = data.refreshStatus;
                }
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const serviceCall = this.service.refreshCache().pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }
}
