import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Globals} from '../globals';

@Injectable()
export abstract class AbstractRestService {
    abstract getRestRoot(): string;

    constructor(
        protected http: HttpClient,
        protected globals: Globals
    ) {
    }

    // TODO: Add remaining http methods as required...
    get(url: string, contentType?: string, paramsObj?: Object): Observable<Object> {
        const headers = new HttpHeaders(this.headers(undefined, contentType));
        const params = this.params(paramsObj);
        const options = {headers, params, withCredentials: true};
        return this.http.get(this.getRestRoot() + url, options);
    }

    post(url: string, data: any, contentType?: string) {
        const headers = new HttpHeaders(this.headers(undefined, contentType));
        const options: any = {headers, withCredentials: true};
        const body = JSON.stringify(data);
        return this.http.post(this.getRestRoot() + url, body, options);
    }

    put(url: string, data: any, contentType?: string) {
        const headers = new HttpHeaders(this.headers(undefined, contentType));
        const options: any = {headers, withCredentials: true};
        const body = JSON.stringify(data);
        return this.http.put(this.getRestRoot() + url, body, options);
    }

    delete(url: string, contentType?: string, paramsObj?: Object) {
        const headers = new HttpHeaders(this.headers(undefined, contentType));
        const params = this.params(paramsObj);
        const options = {headers, params, withCredentials: true};
        return this.http.delete(this.getRestRoot() + url, options);
    }

    headers(accept:string = 'application/json', contentType:string='application/json'): {} {
        return {
            'Accept': accept,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Content-Type': contentType
        };
    }

    params(paramsObj: any): HttpParams {
        let params = new HttpParams();
        for (const key in paramsObj) {
            if (paramsObj.hasOwnProperty(key)) {
                params = params.append(key, paramsObj[key]);
            }
        }
        return params;
    }
}
