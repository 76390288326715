import {Component, OnInit} from '@angular/core';
import {Category} from "../../../../shared/model/Category";
import {Supplier} from "../../../../shared/model/Supplier";
import {map, startWith, take} from "rxjs/operators";
import {AdminService} from "../../../../shared/services/admin.service";
import {EMPTY, Observable, Observer} from "rxjs";

@Component({
    selector: 'app-suppliers',
    templateUrl: './suppliers.component.html',
    styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {

    loading$: Observable<boolean> = EMPTY;

    suppliers: Supplier[] = [];

    constructor(private service: AdminService) {
    }

    ngOnInit(): void {
        const callObserver: Observer<Supplier[]> = {
            next: data => {
                //console.log("data:", data);
                this.suppliers = data;
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const serviceCall = this.service.getSupplierList().pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }

}
