import {Component, OnInit} from '@angular/core';
import {EMPTY, Observable, Observer} from "rxjs";
import {Distribution} from "../../shared/model/Distribution";
import {map, startWith, take} from "rxjs/operators";
import {DistributionService} from "../../shared/services/distribution.service";
import {Truck} from "../../shared/model/Truck";
import {TruckService} from "../../shared/services/truck.service";
import {AllocatedDistribution} from "../../shared/model/AllocatedDistribution";
import {CustomerOrderQuantity} from "../../shared/model/CustomerOrderQuantity";
import {AllocateService} from "../../shared/services/allocate.service";
import {AllocateComponent} from "../allocate/allocate.component";
import {MatDialog} from "@angular/material/dialog";
import {TruckConfirmation} from "../../shared/model/TruckConfirmation";
import {CustomerFilter} from "../../shared/model/CustomerFilter";
import {
    CustomerGroupType,
    CustomerGroupTypeKey,
    is_customer_group_type_key
} from "../../shared/model/CustomerGroupType";
import {DialogService} from "../../shared/services/dialog.service";
import {Item} from "../../shared/model/Item";
import {BaseComponent} from "../base/base.component";
import {Globals} from "../../shared/globals";
import {KeycloakMonitorService} from "../../shared/services/keycloak-monitor.service";

@Component({
    selector: 'app-truck',
    templateUrl: './truck.component.html',
    styles: [`
        .truck {
            padding: 20px;
            width: 100%;
            height: 100%;
        }

        .truck-summary {
            border: 1px solid black;
            padding: 15px;
        }

        .truck-button {
            background-color: #CCCCCC;
            background-image: none;
            background-position: center top;
            background-repeat: repeat-x;
            border: 1px solid #BEB9A6;
            padding: 2px 4px 4px;
            border-radius:5px;
        }

        .truck-detail-button {
            background-color: #CCCCCC;
            background-image: none;
            background-position: center top;
            background-repeat: repeat-x;
            border: 1px solid #BEB9A6;
            padding: 2px 4px 4px;
            border-radius:5px;
        }

        .truck-summary-title {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .truck-summary-total {
            margin-left: 20px;
        }

        .truck-summary-total-text {
            display: inline-block;
            min-width: 100px;
        }

        .truck-summary-total-value {
            display: inline-block;
            min-width: 50px;
            text-align: right;
        }

        .truck-summary-email-text {
            display: inline-block;
        }

        .truck-summary-email-entry {
            display: inline-block;
            margin: 5px;
        }

        .truck-header-td1 {
            vertical-align: text-top;
            text-align: center;

            border-right-width: 1px;
            border-right-style: solid;
            border-right-color: rgb(0, 0, 0);
        }

        .truck-header-td2 {
            vertical-align: text-top;
            text-align: center;

            border-right-width: 1px;
            border-right-style: solid;
            border-right-color: rgb(0, 0, 0);

            border-left-width: 1px;
            border-left-style: solid;
            border-left-color: rgb(0, 0, 0);
        }

        .truck-header-td3 {
            vertical-align: text-top;
            text-align: center;

            border-top-width: 3px;
            border-top-style: solid;
            border-top-color: rgb(0, 0, 0);

            border-right-width: 1px;
            border-right-style: solid;
            border-right-color: rgb(0, 0, 0);

            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgb(0, 0, 0);
        }

        .truck-header-text-stacked {
            text-align: center;

            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgb(0, 0, 0);
        }

        .truck-item-text {
            vertical-align: text-top;
            text-align: center;
            white-space: nowrap;

            border-top-width: 3px;
            border-top-style: solid;
            border-top-color: rgb(0, 0, 0);

            border-right-width: 1px;
            border-right-style: solid;
            border-right-color: rgb(0, 0, 0);

            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgb(0, 0, 0);
        }

        .truck-item-text-1 {
            text-align: center;
            white-space: nowrap;

            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgb(0, 0, 0);
        }

        .truck-item-text-2 {
            padding-right: 5px;
            text-align: right;

            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgb(0, 0, 0);
        }

        .truck-item-text-3 {
            vertical-align: text-top;
            text-align: center;
            white-space: nowrap;

            border-top-width: 3px;
            border-top-style: solid;
            border-top-color: rgb(0, 0, 0);

            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgb(0, 0, 0);
        }

        .truck-item-text-4 {
            vertical-align: text-top;
            text-align: center;
            white-space: nowrap;

            border-top-width: 3px;
            border-top-style: solid;
            border-top-color: rgb(0, 0, 0);

            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgb(0, 0, 0);
        }

        .truck-item-col6-text {
            padding-right: 5px;
            text-align: right;

            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgb(0, 0, 0);
        }

        .distribution-image {
            border: 1px solid silver;
            float: left;
            position: relative;
            height: 40px;
            width: 40px;
            top: 25%;
            margin-left: 5px;
            margin-right: 5px;
        }

        .smsstyle_table_shading_hdr {
            font-size: 11px;
            font-weight: normal;
            vertical-align: bottom;
            background-color: #CCCCCC;
        }

        .smsstyle_hdr_txt {
            color: #000000;
            font-weight: bold;
            text-transform: uppercase;
        }

        .smsstyle_table_shading_wht {
            font-size: 11px;
            font-weight: normal;
            vertical-align: top;
            background-color: #FFFFFF;
        }
    `]
})
export class TruckComponent extends BaseComponent implements OnInit {

    loading$: Observable<boolean> = EMPTY;

    truck?: Truck = { distributionList: [] };

    truckCount?: number = 0;
    distributionList?: Array<Distribution> = [];
    showEmptyTrucksMessage = true;
    confirmationMessage?: string;

    constructor(
        protected globals: Globals,
        private distributionService: DistributionService,
        private allocateService: AllocateService,
        private dialogService: DialogService,
        private truckService: TruckService,
        protected dialog: MatDialog,
        protected keycloakMonitorService: KeycloakMonitorService) {
        super(globals, dialog, keycloakMonitorService);
    }

    ngOnInit(): void {
        //console.log("ngOnInit");
        super.ngOnInit();
    }

    initComp() {
        //console.log("Initializing Component");
        this.getTruck();
    }

    getDistributionImageUrl(distribution: Distribution): string {
        return distribution?.imageUrl!;
    }

    getItemImageUrl(item: Item): string {
        return item.imageUrl;
    }

    getTruck() {
        this.distributionList = [];
        const truckObserver: Observer<Truck> = {
            next: data => {
                //console.log("truck data:", data);
                this.truck = data;
                //this.distributionList = this.truck.distributionList;
                if (this.truck &&
                    (this.truck.distributionList && this.truck.distributionList.length > 0) ||
                    (this.truck.allocatedDistributionList && this.truck.allocatedDistributionList.length > 0)) {
                    this.showEmptyTrucksMessage = false;
                }

                this.truck.distributionList?.forEach(d => {
                    if (this.truck) {
                        let list = this.truck.allocatedDistributionList?.filter((ad) => d.key == ad.id);
                        if (list && list.length > 0) {
                            // Skip the distribution it is already in the list
                        } else {
                            d.allocated = false;
                            this.distributionList?.push(d);
                        }
                    }
                });

                this.truck.allocatedDistributionList?.forEach(ad => {
                    if (ad.distribution) {
                        let dist = ad.distribution;
                        dist.allocated = true;
                        dist.showStores = false;
                        dist.allocatedShipDate = ad.shipDate;
                        let sumItemQuantities = 0;
                        let extCost = 0.0;
                        let extAllow = 0.0;
                        let extNet = 0.0;
                        //console.log("getTruck ad:", ad.allocatedStoreList);
                        ad.allocatedStoreList?.forEach(coq => {
                            ad.group = coq.customerGroup?.groupDescription;
                            sumItemQuantities = sumItemQuantities + coq.orderQuantity!;
                            extCost = extCost + coq.orderQuantity! * dist.billCost!;
                            extAllow = extAllow + coq.orderQuantity! * dist.allowance!;
                            extNet = extNet + coq.orderQuantity! * (dist.billCost! - dist.allowance!);
                        });
                        //console.log(`getTruck extCost: ${extCost} extAllow: ${extAllow} extNet:${extNet} `);
                        ad.extCost = extCost;
                        ad.extAllow = extAllow;
                        ad.extNet = extNet;
                        dist.orderQuantity = sumItemQuantities;
                        //console.log("getTruck ad:", ad);
                        dist.allocatedGroup = ad.group;
                        this.distributionList?.push(dist);
                    }
                });

                this.truckCount = this.truck.uniqueItems;
                //console.log("getTruck this.distributionList:", this.distributionList);
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const truckServiceCall = this.truckService.getTruck().pipe(take(1));
        this.loading$ = truckServiceCall.pipe(map(_ => false), startWith(true));
        truckServiceCall.subscribe(truckObserver);
    }

    getNumberOfAllocatedStoresForDist(index: number, distribution: Distribution): number {
        //console.log("getNumberOfAllocatedStoresForDist index:", index);
        if (distribution.allocatedShipDate) {
            let ad = this.getAllocatedDistribution(index, distribution.allocatedShipDate, distribution.allocatedGroup!);
            //console.log("getNumberOfAllocatedStoresForDist ad:", ad);
            if (ad && ad.distribution) {
                if (this.truck && this.truck.allocatedDistributionList) {
                    return ad.allocatedStoreList!.length;
                }
            }
        }
        return 0;
    }

    toggleShowStores(index: number, distribution: Distribution) {
        //console.log("toggleShowStores index:", index);
        if (distribution.allocatedShipDate) {
            let ad = this.getAllocatedDistribution(index, distribution.allocatedShipDate, distribution.allocatedGroup!);
            //console.log("toggleShowStores ad:", ad);
            if (ad && ad.distribution) {
                let dist = ad.distribution;
                if (dist) {
                    dist.showStores = !dist.showStores;
                }
            }
        }
    }

    getShowStoresButtonText(distribution: Distribution) {
        return distribution.showStores?`Hide Stores`:`Show Stores`;// ${distribution.key}`;
    }

    getAllocatedDistribution(index: number, shipDate: string, group: string): AllocatedDistribution | undefined {
        if (this.distributionList && this.truck) {
            let dist = this.distributionList[index];
            let distKey = dist.key;
            let list = this.truck.allocatedDistributionList?.filter((ad) => distKey == ad.id &&
                shipDate == ad.shipDate &&
                (group ? group == ad.group : true));
            if (list && list.length == 1) {
                return list[0];
            }
        }
        return undefined;
    }

    getDistributionStores(index: number, distribution: Distribution): CustomerOrderQuantity[] {
        //console.log("getDistributionStores index:", index);
        if (distribution.allocatedShipDate) {
            let ad = this.getAllocatedDistribution(index, distribution.allocatedShipDate, distribution.allocatedGroup!);
            //console.log("getDistributionStores ad:", ad);
            if (ad && ad.distribution) {
                if (ad.allocatedStoreList) {
                    return ad.allocatedStoreList!;
                }
            }
        }
        return [];
    }

    getExtValue(extProp: string, index: number, distribution: Distribution): string {
        if (distribution.allocatedShipDate) {
            let ad = this.getAllocatedDistribution(index, distribution.allocatedShipDate, distribution.allocatedGroup!);
            //console.log("getExtValue ad:", ad);
            if (ad) {
                switch (extProp) {
                    case 'extCost':
                        return ad.extCost == 0 ? '-' : ad.extCost!.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        });
                        break;
                    case 'extAllow':
                        return ad.extAllow == 0 ? '-' : ad.extAllow!.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        });
                        break;
                    case 'extNet':
                        return ad.extNet == 0 ? '-' : ad.extNet!.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        });
                        break;
                }
            }
        }
        return '-';
    }

    getOrderTotal(): string {
        let rtn = "";
        if (this.truck && this.truck.totalCost && this.truck.totalAllowance) {
            rtn = (this.truck?.totalCost - this.truck.totalAllowance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        }
        return rtn;
    }

    allocateClicked(index: number, distribution: Distribution) {
        //console.log("allocateClicked index:", index);
        let dist = distribution;
        if (distribution.allocatedShipDate) {
            let ad = this.getAllocatedDistribution(index, distribution.allocatedShipDate, distribution.allocatedGroup!);
            //console.log("allocateClicked ad:", ad);
            if (ad && ad.distribution) {
                dist = ad.distribution;
            }
        }
        let dialogRef = this.dialog.open(AllocateComponent, {
            height: '700px',
            width: '900px',
        });
        let allocate = dialogRef.componentInstance;
        allocate.dialogMode = true;
        allocate.updateDistribution(dist);
        dialogRef.afterClosed().subscribe((result) => {
            //console.log("result:", result);
            this.getTruck();
        });
    }

    removeFromTruckClicked(index: number, distribution: Distribution) {
        let dist = distribution;
        let shipDate = '2999-01-01';
        let cusGrpTyp: CustomerGroupTypeKey = "DRACO";
        let cusGrpId: string | undefined = "";
        let cusDivPart = 0;
        let cusId = "ALLOCATE";
        shipDate = distribution.allocatedShipDate ? distribution.allocatedShipDate : shipDate;
        let group = distribution.allocatedGroup ? distribution.allocatedGroup : "";
        let ad = this.getAllocatedDistribution(index, shipDate, group);
        //console.log("removeFromTruckClicked ad:", ad);
        if (ad && ad.distribution) {
            dist = ad.distribution;
            let coq = ad.allocatedStoreList ? ad.allocatedStoreList[0] : undefined;
            if (coq && coq.customerGroup && coq.customerGroup.groupType) {
                if (is_customer_group_type_key(coq.customerGroup.groupType)) {
                    cusGrpTyp = coq.customerGroup.groupType;
                }
                cusGrpId = coq.customerGroup.groupName;
                if (coq.customerGroup.division && coq.customerGroup.division.divPart) {
                    cusDivPart = coq.customerGroup.division.divPart;
                }
                if (coq && coq.customerGroup.groupName) {
                    cusId = coq.customerGroup.groupName;
                }
            }
            shipDate = ad.shipDate ? ad.shipDate : shipDate;
        }
        let itemNum = 0;
        if (!dist?.bundle && dist?.itemSet) {
            itemNum = dist.itemSet[0].id;
        }
        let defaultAllocateCustomerFilter: CustomerFilter = {
            distributionId:dist.id,
            itemNumber:itemNum,
            mcLaneDiv:"MC",
            divPart:0,
            customerGroupType: cusGrpTyp,
            customerGroupId:cusGrpId,
            custDivPart:cusDivPart,
            custId:cusId,
            shipDate:shipDate,
        }
        //console.log("defaultAllocateCustomerFilter:", defaultAllocateCustomerFilter);
        const callObserver: Observer<void> = {
            next: data => {
                this.getTruck();
                window.location.reload();
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const serviceCall = this.allocateService.addRemoveTruck(defaultAllocateCustomerFilter, 'delete').pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }

    getDollar(value: string, distribution: Distribution, level: "DIST" | "ITEM"): string {
        let billCost = distribution.billCost;
        let allow = distribution.allowance;
        if (level == "ITEM") {
            billCost = distribution.itemSet![0].billCost;
            allow = distribution.itemSet![0].allowance;
        }
        switch (value) {
            case 'billCost':
                if (billCost == 0 || billCost == undefined) return '-';
                return billCost.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                break;
            case 'allow':
                if (allow == 0 || allow == undefined) return '-';
                return allow.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                break;
            case 'netCost':
                if (billCost == 0 || billCost == undefined) return '-';
                if (allow == 0 || allow == undefined) return '-';
                let netCost = billCost - allow;
                return netCost.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2 });
                break;
        }
        return '-';
    }

    completeOrder() {
        // Verify Email set focus on bad email
        let emailEntry = <HTMLInputElement> document.getElementById('emailEntry');
        if (emailEntry) {
            //("emailEntry:", emailEntry.value.trim());
            let regexp = new RegExp('[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}');
            if (regexp.test(emailEntry.value.trim())) {
                // Verify at least 1 allocated distribution exists
                if (this.truck?.allocatedDistributionList?.length == 0) {
                    this.dialogService.alert({
                        title: "Complete Order",
                        message: "At least one item in the order must be allocated to complete the order.",
                        confirmText: "Ok"
                    });
                } else {
                    // Warn if any unallocated distributions exist
                    if (this.unalloctedDistributions()) {
                        this.dialogService.confirm({
                            title: "Complete Order",
                            message: "Warning there are items/bundles that have not been allocated, these items will be removed from the truck. Do you wish to continue?",
                            cancelText: "Cancel",
                            confirmText: "Continue"
                        }).subscribe(confirmed => {
                            if (confirmed) {
                                this.callCompleteOrderService();
                            }
                        });
                    } else {
                        this.callCompleteOrderService();
                    }
                }
            } else {
                this.dialogService.alert({
                    title: "Complete Order",
                    message: "Please enter a valid email address.",
                    confirmText: "Ok"
                }).subscribe(confirmed => {
                    if (confirmed) {
                        emailEntry.focus();
                    }
                });
            }
        }
    }

    private callCompleteOrderService() {
        // call submit REST service
        const callObserver: Observer<TruckConfirmation> = {
            next: data => {
                // Display confirmation message or any errors
                //console.log("TruckComponent completeOrder data:", data);
                if (data.confirmation) {
                    this.distributionList = [];
                    //console.log("truck cleared");
                    let confDate = data.confirmation.substr(0, 10);
                    //console.log("TruckComponent confDate:", confDate);
                    let confString = data.confirmation.split('.').join("");
                    //console.log("TruckComponent confString:", confString);
                    confString = confString.split('-').join("");
                    //console.log("TruckComponent confString:", confString);
                    this.confirmationMessage = `Your order has been successfully created. Order number and date: ${confString} and ${confDate}`;
                    //console.log("TruckComponent confirmationMessage:", this.confirmationMessage);
                }
            },
            error: _ => {
            },
            complete: () => {
            }
        };
        const serviceCall = this.truckService.completeOrder().pipe(take(1));
        this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
        serviceCall.subscribe(callObserver);
    }

    private unalloctedDistributions(): boolean {
        let unallocated = false;
        this.distributionList?.forEach(d => {
            if (d.allocated == false) {
                unallocated = true;
            }
        });
        return unallocated;
    }

    getDistributionSupplierName(distribution: Distribution): string {
        if (distribution.itemSet) {
           return distribution.itemSet[0].supplier.name;
        }
        return '-';
    }

    getDistributionGroupName(index: number, distribution: Distribution) {
        //console.log("canShowStores index:", index);
        if (distribution.allocatedShipDate) {
            let ad = this.getAllocatedDistribution(index, distribution.allocatedShipDate, distribution.allocatedGroup!);
            //console.log("canShowStores ad:", ad);
            if (ad && ad.distribution) {
                if (ad.allocatedStoreList && ad.allocatedStoreList[0].customerGroup) {
                    return ad.allocatedStoreList[0].customerGroup.groupDescription;
                }
            }
        }
        return '-';
    }

    getDistributionShipDate(index: number, distribution: Distribution) {
        if (distribution.allocatedShipDate) {
            let ac = this.getAllocatedDistribution(index, distribution.allocatedShipDate, distribution.allocatedGroup!);
            if (ac) {
                //console.log("TruckComponent getDistributionGroupName ac:" , ac);
                return ac.shipDate;
            }
        }
        return '-';
    }

    getStoreDivision(store: CustomerOrderQuantity) {
        if (store.customerGroup && store.customerGroup.division) {
            return store.customerGroup.division.mcLaneDiv;
        }
        return '';
    }

    canShowStores(index: number, distribution: Distribution): boolean {
        //console.log("canShowStores index:", index);
        if (distribution.allocatedShipDate) {
            let ad = this.getAllocatedDistribution(index, distribution.allocatedShipDate, distribution.allocatedGroup!);
            //console.log("canShowStores ad:", ad);
            if (ad && ad.distribution) {
                return ad.distribution.showStores!;
            }
        }
        return false;
    }
}
