<div style="padding: 20px;">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <p>
            Number of Image Urls loaded={{itemUrls.length}}
        </p>
        <code>
            <ol>
                <li *ngFor="let itemUrl of itemUrls">
                    <div>{{getItemUrlText(itemUrl)}}</div>
                </li>
            </ol>
        </code>
    </div>
</div>
