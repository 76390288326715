import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Distribution} from "../model/Distribution";
import {Observable} from "rxjs";
import {RestService} from "./rest.service";
import {CustomerOrderQuantity} from "../model/CustomerOrderQuantity";
import {CustomerFilter} from "../model/CustomerFilter";
import {Action} from "rxjs/internal/scheduler/Action";
import {Globals} from "../globals";

@Injectable({
  providedIn: 'root'
})
export class AllocateService {

  constructor(
      protected globals: Globals,
      protected http: HttpClient,
      private restService: RestService) { }

    getDistributionsInTruck(): Observable<Distribution[]> {
        let getPath = this.globals.getRestBase() + "allocate/truck/distributions";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<Distribution[]>(getPath, options);
    }

    getCustomerOrderQuantities(customerFilter: CustomerFilter): Observable<CustomerOrderQuantity[]> {
        let getPath = this.globals.getRestBase() + `allocate/customers`;
        getPath += `${customerFilter.customerGroupType? `?customerGroupType=${customerFilter.customerGroupType}`:''}`;
        getPath += `${customerFilter.customerGroupId? `&customerGroupId=${customerFilter.customerGroupId}`:''}`;
        getPath += `${customerFilter.mcLaneDiv? `&mcLaneDiv=${customerFilter.mcLaneDiv}`:''}`;
        getPath += `${customerFilter.distributionId? `&distributionId=${customerFilter.distributionId}`:''}`;
        getPath += `${customerFilter.itemNumber? `&itemNumber=${customerFilter.itemNumber}`:'&itemNumber=0'}`;
        getPath += `${customerFilter.shipDate? `&shipDate=${customerFilter.shipDate}`:''}`;
        //console.log("AllocateService getCustomerOrderQuantities getPath", getPath);
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<CustomerOrderQuantity[]>(getPath, options);
    }

    getShipDatesForDistribution(customerFilter: CustomerFilter): Observable<string[]> {
        let getPath = this.globals.getRestBase() + "allocate/truck/distributions/shipdates";
        getPath += `${customerFilter.distributionId? `?distributionId=${customerFilter.distributionId}`:''}`;
        getPath += `${customerFilter.itemNumber? `&itemNumber=${customerFilter.itemNumber}`:'&itemNumber=0'}`;
        getPath += `${customerFilter.mcLaneDiv? `&mcLaneDiv=${customerFilter.mcLaneDiv}`:''}`;
        getPath += `${customerFilter.divPart? `&divPart=${customerFilter.divPart}`:''}`;
        getPath += `${customerFilter.customerGroupType? `&customerGroupType=${customerFilter.customerGroupType}`:''}`;
        getPath += `${customerFilter.customerGroupId? `&customerGroupId=${customerFilter.customerGroupId}`:''}`;
        //console.log("AllocateService getShipDatesForDistribution getPath", getPath);
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<string[]>(getPath, options);
    }

    addRemoveTruck(customerFilter: CustomerFilter, action: string): Observable<void> {
        //console.log("AllocateService addRemoveTruck distribution:", distribution);
        let getPath = this.globals.getRestBase() + "allocate/truck/distributions/" + action;
        getPath += `${customerFilter.distributionId? `?distributionId=${customerFilter.distributionId}`:'?distributionId='}`;
        getPath += `${customerFilter.itemNumber? `&itemNumber=${customerFilter.itemNumber}`:'&itemNumber=0'}`;
        getPath += `${customerFilter.mcLaneDiv? `&mcLaneDiv=${customerFilter.mcLaneDiv}`:'&mcLaneDiv='}`;
        getPath += `${customerFilter.divPart? `&divPart=${customerFilter.divPart}`:'&divPart=0'}`;
        getPath += `${customerFilter.customerGroupType? `&clsTyp=${customerFilter.customerGroupType}`:'&clsTyp='}`;
        getPath += `${customerFilter.customerGroupId? `&clsId=${customerFilter.customerGroupId}`:'&clsId='}`;
        getPath += `${customerFilter.custDivPart? `&custDivPart=${customerFilter.custDivPart}`:'&custDivPart=0'}`;
        getPath += `${customerFilter.custId? `&custId=${customerFilter.custId}`:'&custId='}`;
        getPath += `${customerFilter.shipDate ? `&shipDate=${customerFilter.shipDate}`:'&shipDate=2999-01-01'}`;
        //console.log("AllocateService addRemoveTruck getPath", getPath);
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<void>(getPath, options);
    }

    updateTruck(customerFilter: CustomerFilter | undefined, list: CustomerOrderQuantity[]): Observable<void> {
        //console.log("AllocateService updateTruck customerFilter:", customerFilter);
        let getPath = this.globals.getRestBase() + "allocate/truck/distributions/update";
        getPath += `${customerFilter?.distributionId? `?distributionId=${customerFilter.distributionId}`:''}`;
        getPath += `${customerFilter?.itemNumber==undefined ? `&itemNumber=0` : `&itemNumber=${customerFilter.itemNumber}`}`;
        getPath += `${customerFilter?.shipDate? `&shipDate=${customerFilter.shipDate}`:''}`;
        //console.log("AllocateService updateTruck getPath", getPath);
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.post<void>(getPath, list, options);
    }

}
