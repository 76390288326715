<div style="padding: 20px;">
    <p>
    Cache id={{cacheId}}
    </p>
    <p>Cache refresh timestamps since last restart count={{cacheRefreshTimestamps.length}}</p>
    <code>
        <ol>
            <li *ngFor="let cacheTimestamp of cacheRefreshTimestamps">
                <div>{{cacheTimestamp}}</div>
            </li>
        </ol>
    </code>
    <p>Cache monitor wake timestamps since last restart count={{cacheMonitorTimestamps.length}}</p>
    <code>
        <ol>
            <li *ngFor="let cacheTimestamp of cacheMonitorTimestamps">
                <div>{{cacheTimestamp}}</div>
            </li>
        </ol>
    </code>
</div>
