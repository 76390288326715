<div style="padding: 20px;">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <p>
            Number of Distribution Lists loaded={{distributionLists.length}}
        </p>
        <code>
            <ol>
                <li *ngFor="let list of distributionLists">
                    <div>{{getListKey(list)}}</div>
                    <ol *ngIf="getList(list)">
                        <li *ngFor="let dist of getList(list)">
                            <div>{{dist.key}} - {{dist.description}}{{dist.bundle ? '' : ' - ' + dist.itemSet[0].description + ' - ' + dist.itemSet[0].category.name + ' - ' + dist.itemSet[0].supplier.name}}</div>
                        </li>
                    </ol>
                </li>
            </ol>
        </code>
    </div>
</div>
