import {
    ChangeDetectionStrategy,
    Component,
    ElementRef, EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Optional, Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {CustomerFilter} from "../../shared/model/CustomerFilter";
import {first, map, startWith, take, tap} from "rxjs/operators";
import {BehaviorSubject, EMPTY, Observable, Observer, Subject} from "rxjs";
import {Distribution} from "../../shared/model/Distribution";
import {AllocateService} from "../../shared/services/allocate.service";
import {Globals} from "../../shared/globals";

@Component({
    selector: 'app-allocate',
    templateUrl: './allocate.component.html',
    styles: [`
        .allocate {
            width: 100%;
            height: 100%;
            background-color: silver;
            color: white;
        }

        .allocate-content {
            background-color: white;
            color: black;
            margin: 10px;
            width: calc(100% - 30px);
            height: calc(100% - 40px);
        }

        .help:hover {
            background-image: url("../../../assets/img/help.png");
            background-position-x: -23px;
            background-position-y: 100%;
        }

        .top-section {
            width: 100%;
            height: 45%;
        }

        .bottom-section {
            width: 100%;
            height: 55%;
            text-align: center;
        }

        .top-row1 {
            width: 100%;
            height: 50%;
        }

        .top-row2 {
            width: 100%;
            height: 50%;
        }

        .top-row1-column1 {
            border: 1px solid silver;
            border-collapse: collapse;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            width: calc(50%);
            height: calc(100%);
        }

        .top-row1-column2  {
            border: 1px solid silver;
            border-collapse: collapse;
            box-sizing: border-box;
            display: inline-block;
            text-align: center;
            width: calc(50%);
            height: calc(100%);
        }

        .top-row2-column1 {
            border: 1px solid silver;
            border-collapse: collapse;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            width: calc(50%);
            height: calc(100%);
        }

        .top-row2-column2  {
            border: 1px solid silver;
            border-collapse: collapse;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            width: calc(50%);
            height: calc(100%);
        }

        .allocate .actions {
            position: relative;
            top: 25%;
        }

        button {
            margin: 5px;
            width: 200px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllocateComponent implements OnInit, OnChanges {

    loading$: Observable<boolean> = EMPTY;

    @Input() dialogMode?: boolean = false;

    public distributionKeySubject: Subject<string> = new Subject<string>();
    @Input() distributionKey$: Observable<string> = this.distributionKeySubject.asObservable();
    @Input() distribution?: Distribution;

    @Output() distributionChanged = new EventEmitter<Distribution>();

    @ViewChild('truckLink', { static: false }) private truckLink!: ElementRef;

    private customerFilterSubject: BehaviorSubject<CustomerFilter> = new BehaviorSubject<CustomerFilter>({});
    public customerFilter$: Observable<CustomerFilter> = this.customerFilterSubject.asObservable();
    public reviewOrderLink = 'https://mclaneedge.com/shop';

    constructor(
        protected globals: Globals,
        private allocateService: AllocateService,
        @Optional() private dialogRef: MatDialogRef<AllocateComponent>
    ) {
        if (window.location.hostname.includes('mclaneedgedev')) {
            this.reviewOrderLink = 'https://mclaneedgedev.wpengine.com/shop';
        }
    }

    ngOnInit(): void {
        //console.log("allocate ngOnInit");
    }

    ngOnChanges(changes: SimpleChanges): void {
        //console.log("AllocateComponent ngOnChanges changes: ", changes);
        if (changes.distribution) {
            this.updateDistribution(this.distribution!);
        }
    }

    addToTruck() {
        if (this.distribution) {
            let itemNum = 0;
            if (!this.distribution.bundle && this.distribution.itemSet) {
                itemNum = this.distribution.itemSet[0].id;
            }
            let defaultAllocateCustomerFilter: CustomerFilter = {
                distributionId:this.distribution.id,
                itemNumber:itemNum,
                mcLaneDiv:"",
                divPart:0,
                customerGroupId:"",
                custDivPart:0,
                custId:"ALLOCATE",
                shipDate:"2999-01-01",
            }
            const callObserver: Observer<void> = {
                next: data => {
                    //console.log("AllocateComponent addToTruck data: ", data);
                },
                error: _ => {
                },
                complete: () => {
                }
            };
            const serviceCall = this.allocateService.addRemoveTruck(defaultAllocateCustomerFilter, 'add').pipe(take(1));
            this.loading$ = serviceCall.pipe(map(_ => false), startWith(true));
            serviceCall.subscribe(callObserver);
        }
    }

    updateDistribution($event: Distribution) {
        //console.log("AllocateComponent updateDistribution:", $event);
        this.distribution = $event;
        this.addToTruck();
        if (this.distribution) {
            let item = this.distribution.itemSet![0];
            let custFilter = this.customerFilterSubject.getValue();
            let tempFilter: CustomerFilter = {
                loginType: custFilter.loginType,
                customerGroupType: custFilter.customerGroupType,
                customerGroupId: custFilter.customerGroupId,
                divPart: custFilter.divPart,
                mcLaneDiv: custFilter.mcLaneDiv,
                distributionId: this.distribution.id ? this.distribution.id : custFilter.distributionId,
                itemNumber: this.distribution.bundle ? 0 : (item.id ? item.id : custFilter.itemNumber),
                shipDate: (custFilter.shipDate ? custFilter.shipDate : this.distribution?.firstShipDate)
            };
            //console.log("AllocateComponent distributionChanged tempFilter=", tempFilter);
            this.customerFilterSubject.next(tempFilter);
        }
        this.distributionChanged.emit($event);
    }

    customerFilterChanged($event: CustomerFilter) {
        //console.log("AllocateComponent customerFilterChanged event={}", $event);
        let custFilter = this.customerFilterSubject.getValue();
        let tempFilter: CustomerFilter = {
            loginType: $event.loginType? $event.loginType : custFilter.loginType,
            customerGroupType: $event.customerGroupType? $event.customerGroupType : custFilter.customerGroupType,
            customerGroupId: $event.customerGroupId? $event.customerGroupId : custFilter.customerGroupId,
            mcLaneDiv: $event.mcLaneDiv? $event.mcLaneDiv: custFilter.mcLaneDiv,
            divPart: $event.divPart? $event.divPart: custFilter.divPart,
            distributionId: $event.distributionId? $event.distributionId: custFilter.distributionId,
            itemNumber: $event.itemNumber? $event.itemNumber: custFilter.itemNumber,
            shipDate: $event.shipDate ? $event.shipDate : (custFilter.shipDate ? custFilter.shipDate : this.distribution?.firstShipDate)
        };
        //console.log("AllocateComponent customerFilterChanged tempFilter=", tempFilter);
        this.customerFilterSubject.next(tempFilter);
    }

    close() {
        if (this.dialogMode) {
            this.dialogRef.close();
        }
    }

    reviewOrder() {
        if (this.dialogMode) {
            this.dialogRef.afterClosed().pipe(
                tap(() => this.truckLink.nativeElement.click()),
                first()
            ).subscribe();
            this.dialogRef.close();
        }
        else {
            this.truckLink.nativeElement.click();
        }
    }

    shipDateChanged($event: string) {
        //console.log("AllocateComponent shipDateChanged event=", $event);
        let custFilter = this.customerFilterSubject.getValue();
        let tempFilter: CustomerFilter = {
            loginType: custFilter.loginType,
            customerGroupType: custFilter.customerGroupType,
            customerGroupId: custFilter.customerGroupId,
            mcLaneDiv: custFilter.mcLaneDiv,
            divPart: custFilter.divPart,
            distributionId: custFilter.distributionId,
            itemNumber: custFilter.itemNumber,
            shipDate: $event
        };
        //console.log("AllocateComponent shipDateChanged tempFilter=", tempFilter);
        this.customerFilterSubject.next(tempFilter);
    }

    isEdgeHost(): boolean {
        return this.globals.isEdgeHost();
    }
}
