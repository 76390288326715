<div style="padding: 20px;">
    <div class="loading-wrapper">
        <div *ngIf="loading$ | async" class="loading"><span>Loading...</span></div>
        <p>
            Number of Categories loaded={{categories.length}}
        </p>
        <code>
            <ol>
                <li *ngFor="let category of categories">
                    <div>{{category.id}} - {{category.name}}</div>
                </li>
            </ol>
        </code>
    </div>
</div>
