import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {KeycloakService} from "keycloak-angular";

@Injectable({
    providedIn: 'root'
})
export class KeycloakMonitorService {

    private loggedInSubject = new Subject<KeycloakService>();
    public loggedIn$ = this.loggedInSubject.asObservable();

    constructor() {
    }

    public setLoggedIn(keycloak: KeycloakService) {
        this.loggedInSubject.next(keycloak);
    }
}
