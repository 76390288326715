import {Injectable} from '@angular/core';
import {ApplicationSystemParameter} from "../model/ApplicationSystemParameter";
import {Observable, Subject} from "rxjs";
import {RestService} from "./rest.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {CachedDistribution} from "../model/CachedDistribution";
import {TradeshowGrouping} from "../model/TradeshowGrouping";
import {Category} from "../model/Category";
import {Supplier} from "../model/Supplier";
import {ItemUrlRecord} from "../model/ItemUrlRecord";
import {RefreshStatus} from "../model/RefreshStatus";
import {CachedDistributionLookup} from "../model/CachedDistributionLookup";
import {UrlMaint} from "../model/UrlMaint";
import {Globals} from "../globals";

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(
        protected http: HttpClient,
        private restService: RestService,
        private globals: Globals) { }

    getParameters(): Observable<ApplicationSystemParameter[]> {
        //console.log("AdminService.getParameters() called");
        let getPath = this.globals.getRestBase() + "admin/parameters";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<ApplicationSystemParameter[]>(getPath, options);
    }

    getCacheId(): Observable<string>  {
        //console.log("AdminService.getCacheId() called");
        let getPath = this.globals.getRestBase() + "admin/cache-id";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers('text/plain'));
        const params: HttpParams = this.restService.params(undefined);
        return this.http.get(getPath, {headers, params, responseType: 'text', withCredentials: true});
    }

    getRefreshTimeStampList(): Observable<Object>  {
        //console.log("AdminService.getRefreshTimeStampList() called");
        let getPath = this.globals.getRestBase() + "admin/cache-refresh-timestamps";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<any>(getPath, options);
    }

    getMonitorTimeStampList(): Observable<Object>  {
        //console.log("AdminService.getMonitorTimeStampList() called");
        let getPath = this.globals.getRestBase() + "admin/cache-monitor-timestamps";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<any>(getPath, options);
    }

    getDistributionList(): Observable<CachedDistribution[]>  {
        //console.log("AdminService.getDistributionList() called");
        let getPath = this.globals.getRestBase() + "admin/distributions";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<CachedDistribution[]>(getPath, options);
    }

    getTradeshowGroupingList(): Observable<TradeshowGrouping[]>  {
        //console.log("AdminService.getTradeshowGroupingList() called");
        let getPath = this.globals.getRestBase() + "admin/tradeshow-groupings";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<TradeshowGrouping[]>(getPath, options);
    }

    getCategoryList(): Observable<Category[]>  {
        //console.log("AdminService.getCategoryList() called");
        let getPath = this.globals.getRestBase() + "admin/categories";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<Category[]>(getPath, options);
    }

    getSupplierList(): Observable<Supplier[]>  {
        //console.log("AdminService.getCategoryList() called");
        let getPath = this.globals.getRestBase() + "admin/supplier";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<Supplier[]>(getPath, options);
    }

    getItemUrls(): Observable<ItemUrlRecord> {
        //console.log("AdminService.getCategoryList() called");
        let getPath = this.globals.getRestBase() + "admin/item/urls";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<ItemUrlRecord>(getPath, options);
    }

    getLastUpdate() {
        //console.log("AdminService.getLastUpdate() called");
        let getPath = this.globals.getRestBase() + "test/version";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<any>(getPath, options);
    }

    refreshCache(): Observable<RefreshStatus> {
        let getPath = this.globals.getRestBase() + "admin/cache/refresh";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<RefreshStatus>(getPath, options);
    }

    getCachedDistributionLists(): Observable<CachedDistributionLookup> {
        //console.log("AdminService.getCachedDistributionLists() called");
        let getPath = this.globals.getRestBase() + "admin/distribution/lists";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        //console.log("AdminService getCachedDistributionLists getPath", getPath);
        return this.http.get<CachedDistributionLookup>(getPath, options);
    }

    saveUrl(urlMaint: UrlMaint): Observable<any> {
        let getPath = this.globals.getRestBase() + "admin/url/maint";
        //console.log("AdminService.saveUrl getPath", getPath);
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.post<any>(getPath, urlMaint, options);
    }
}
