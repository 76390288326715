import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {RestService} from "./rest.service";
import {Globals} from "../globals";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    readonly USERNAME = '/user/id';

    constructor(protected globals: Globals,
                private http: HttpClient,
                private restService: RestService) {
    }

    getUserId(): Observable<any> {
        let getPath = this.globals.getRestBase() + "user/id";
        const headers: HttpHeaders = new HttpHeaders(this.restService.headers(undefined, undefined));
        const params: HttpParams = this.restService.params(undefined);
        const options = {headers, params, withCredentials: true};
        return this.http.get<any>(getPath, options);
    }

}
